import { Box, Grid, Link, Stack, Typography } from '@mui/material';
import { Helmet } from 'react-helmet-async';
import InfiniteScroll from 'react-infinite-scroll-component';
import AdUnit from '../commons/components/ads/AdUnit';
import Empty from '../commons/components/Empty';
import Loading from '../commons/components/Loading';
import env from '../commons/configs/env';
import { Material, MaterialPage, Type } from '../commons/openapi';
import MaterialCard from '../material/components/MaterialCard';
import { useInfinityFetchSummaryMaterials } from '../material/hooks/useMaterialAPI';


function BlogSearchPage() {
  const { data, isFetching, hasNextPage, fetchNextPage } = useInfinityFetchSummaryMaterials(Type.Post);
  const posts = data?.pages.reduce((posts: Material[], page: MaterialPage) => {
    return [...posts, ...page.materials];
  }, []);

  const renderMore = (): JSX.Element => {
    return <Box width='100%' sx={{ display: 'flex', justifyContent: 'center', my: 1 }}>
      <Link href='#' underline='hover' variant='inherit' onClick={() => fetchNextPage()}>More</Link>
    </Box>
  }

  return (
    <Box component='section'>

      <Helmet>
        <title>Blog | ESL Finder</title>
        <link rel='canonical' href={`${env.canonical}/blog`} />
        <meta name='description' content='Blog about teaching English. Here you will find lesson plans and ideas to use in your ESL classroom.' />
      </Helmet>

      <Stack direction={{ xs: 'column', sm: 'row' }}>

        <Stack minWidth={320} sx={{ mx: 1, my: 13 }} gap={3}>
          <AdUnit slot={env.ads.sidebar} />
          <AdUnit slot={env.ads.sidebar} />
          <AdUnit slot={env.ads.sidebar} />
        </Stack>

        <Box component='section' width='100%' mx={2}>

          <Stack sx={{ m: 5, textAlign: 'center' }}>
            <Typography variant='h1' fontSize={34} fontWeight='bold'>Curated English lessons, Tips, and Resources</Typography>
          </Stack>

          <InfiniteScroll style={{ width: '100%' }} dataLength={posts ? posts.length : 0} next={() => fetchNextPage()} hasMore={hasNextPage ? hasNextPage : false} loader={<></>}>
            <Grid container sx={{ justifyContent: 'left' }} gap={3}>
              {posts?.map((material: Material, i: number) => (
                <MaterialCard key={material.id} material={material} />
              ))}
            </Grid>
          </InfiniteScroll>
          {!isFetching && posts?.length === 0 && (<Empty />)}
          {!isFetching && hasNextPage && renderMore()}
          {isFetching && (<Loading />)}
        </Box>

      </Stack>

    </Box>

  );
}

export default BlogSearchPage;
