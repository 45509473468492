import { sanitizeUrl } from '@braintree/sanitize-url';
import DeleteIcon from '@mui/icons-material/Delete';
import InfoIcon from '@mui/icons-material/Info';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import PreviewIcon from '@mui/icons-material/Preview';
import { Box, Button, Checkbox, Container, FormControl, FormControlLabel, FormGroup, FormLabel, Grid, InputAdornment, Link, Radio, RadioGroup, Stack, TextField, Tooltip, Typography } from '@mui/material';
import he from 'he';
import { ChangeEvent, FormEvent, useEffect, useState } from 'react';
import { AccessPlan, Age, Category, Material, MaterialNew, Level, MediaType, Photo, Status, Type } from '../../commons/openapi';
import { convertToLocalTimezone } from '../../commons/util/DateUtils';
import { getAccessPlans, getAges, getCategories, getLevels, getMediaTypes, getStatuses, getTypes, isLesson } from '../../commons/util/MaterialUtils';
import { capitalize } from '../../commons/util/String';
import MaterialDeleteDialog from './MaterialDeleteDialog';
import MaterialPreviewDialog from './MaterialPreviewDialog';

interface FormData {
  url: string;
  title: string;
  description: string;
  overview: string;
  content: string;
  theme: string;
  teacher_version: boolean;
  type: Type;
  status: Status;
  category: Category;
  access_plan: AccessPlan;
  level: Array<Level>;
  media_type: Array<MediaType>;
  age: Array<Age>;
  photo: Photo;
  published_at: string;
}

interface Props {
  readonly updateMode?: boolean;
  readonly material?: Material,
  readonly onSubmit: (materialNew: MaterialNew) => void,
}

function MaterialForm({ updateMode, material, onSubmit }: Props) {

  const [formState, setFormState] = useState<MaterialNew>({
    url: '',
    title: '',
    description: '',
    overview: '',
    content: '',
    theme: '',
    teacher_version: false,
    type: 'LESSON',
    status: 'PUBLISHED',
    category: 'GENERAL',
    access_plan: 'FREE',
    level: [],
    media_type: [],
    age: [],
    photo: undefined,
    published_at: new Date().toJSON(),
  });

  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [openPreviewDialog, setOpenPreviewDialog] = useState(false);

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    if (name === 'photo.id') {
      if (value) {
        setFormState((prevData) => ({ ...prevData, photo: { id: value } }));
      } else {
        setFormState((prevData) => ({ ...prevData, photo: undefined }));
      }
    } else {
      setFormState((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };

  const handleCheckboxChange = (name: string) => (event: ChangeEvent<HTMLInputElement>) => {
    setFormState((prevData) => ({
      ...prevData,
      [name]: event.target.checked,
    }));
  };

  const handleMultiCheckboxChange = (name: string, value: string) => (event: ChangeEvent<HTMLInputElement>) => {
    const arr: Array<string> = (formState[name as keyof FormData] as string[]) || [];

    if (event.target.checked) {
      setFormState((prevData) => ({
        ...prevData,
        [name]: [...arr, value],
      }));
    } else {
      setFormState((prevData) => ({
        ...prevData,
        [name]: arr.filter((item) => item !== value),
      }));
    }
  };

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const url = isLesson(formState) ? formState.url : undefined;
    const description = isLesson(formState) ? formState.description : undefined;
    const content = isLesson(formState) ? undefined : formState.content;

    onSubmit({
      ...formState, url, description, content, published_at: formState.published_at ? new Date(formState.published_at).toJSON() : new Date().toJSON()
    });
  }

  useEffect(() => {
    setFormState({
      url: material?.url ? material.url : '',
      title: material?.title ? he.decode(material.title) : '',
      overview: material?.overview ? he.decode(material.overview) : '',
      content: material?.content ? he.decode(material.content) : '',
      description: material?.description ? he.decode(material.description) : '',
      theme: material?.theme ? he.decode(material?.theme) : '',
      teacher_version: material?.teacher_version ? material?.teacher_version : false,
      type: material?.type ? material?.type : 'LESSON',
      status: material?.status ? material?.status : 'PUBLISHED',
      category: material?.category ? material?.category : 'GENERAL',
      access_plan: material?.access_plan ? material?.access_plan : 'FREE',
      level: material?.level ? material.level : [],
      media_type: material?.media_type ? material.media_type : [],
      age: material?.age ? material.age : [],
      photo: material?.photo ? material?.photo : undefined,
      published_at: material?.published_at ? convertToLocalTimezone(material?.published_at).slice(0, 16) : new Date().toJSON().slice(0, 16),
    });
  }, [material]);

  const newTabLink = () => {
    if (!formState.url) {
      return <></>
    }

    return <InputAdornment position='end'>
      <Tooltip title='Open in a new tab'>
        <Link href={formState.url} underline='none' color='primary' target='_blank' rel='noreferrer'>
          <OpenInNewIcon color='primary' />
        </Link>
      </Tooltip>
    </InputAdornment>
  }

  return (
    <Container>
      <form onSubmit={handleSubmit}>
        <Grid container spacing={3}>

          <Grid item xs={12} sx={{ mt: 6 }}>
            <FormControl component='fieldset' disabled={updateMode}>
              <FormLabel component='legend' required >Type</FormLabel>
              <RadioGroup row name='type' value={formState.type} onChange={handleChange}>
                {getTypes().map((type) => (
                  <FormControlLabel key={type} value={type} control={<Radio />} label={capitalize(type)} />
                ))}
              </RadioGroup>
            </FormControl>
          </Grid>

          {isLesson(formState) && (<Grid item xs={12}>
            <TextField label='URL' name='url' value={formState.url} onChange={handleChange} required={isLesson(formState)} fullWidth disabled={updateMode} InputProps={{
              endAdornment: newTabLink(),
            }} />
          </Grid>)}

          <Grid item xs={12}>
            <TextField label='Title' name='title' value={formState.title} onChange={handleChange} required fullWidth />
          </Grid>

          <Grid item xs={12}>
            <FormControl component='fieldset'>
              <FormLabel component='legend' required>Status</FormLabel>
              <RadioGroup row name='status' value={formState.status} onChange={handleChange}>
                {getStatuses().map((status) => (
                  <FormControlLabel key={status} value={status} control={<Radio />} label={capitalize(status)} />
                ))}
              </RadioGroup>
            </FormControl>
          </Grid>

          <Grid item xs={12}>
            <FormControl component='fieldset'>
              <FormLabel component='legend' required>Category</FormLabel>
              <RadioGroup row name='category' value={formState.category} onChange={handleChange}>
                {getCategories().map((category) => (
                  <FormControlLabel key={category} value={category} control={<Radio />} label={capitalize(category)} />
                ))}
              </RadioGroup>
            </FormControl>
          </Grid>

          <Grid item xs={12}>
            <TextField label='Published At' name='published_at' type='datetime-local' value={formState.published_at} onChange={handleChange} required fullWidth InputLabelProps={{ shrink: true, }} />
          </Grid>

          <Grid item xs={12}>
            <TextField label='Theme' name='theme' value={formState.theme} onChange={handleChange} fullWidth />
          </Grid>

          <Grid item xs={12}>
            <TextField label='Overview' name='overview' value={formState.overview} onChange={handleChange} required={!isLesson(formState)} fullWidth multiline rows={8} />

            <Stack direction='row' gap={1}>
              <InfoIcon color='primary' fontSize='small' />
              <Typography color='primary' variant='caption'>
                For lessons, if you leave the overview field empty, it will be filled automatically based on the raw description.
              </Typography>
            </Stack>
          </Grid>

          <Grid item xs={12}>
            <FormControl component='fieldset'>
              <FormLabel component='legend' required>Access Plan</FormLabel>
              <RadioGroup row name='access_plan' value={formState.access_plan} onChange={handleChange}>
                {getAccessPlans().map((accessPlan) => (
                  <FormControlLabel key={accessPlan} value={accessPlan} control={<Radio />} label={capitalize(accessPlan)} />
                ))}
              </RadioGroup>
            </FormControl>
          </Grid>

          <Grid item xs={12}>
            <FormControl component='fieldset'>
              <FormLabel component='legend'>Level</FormLabel>
              <FormGroup row>
                {getLevels().map((level) => (
                  <FormControlLabel key={level} label={level}
                    control={
                      <Checkbox checked={formState.level?.includes(level)} onChange={handleMultiCheckboxChange('level', level)} />
                    } />
                ))}
              </FormGroup>
            </FormControl>
          </Grid>

          <Grid item xs={12}>
            <FormControl component='fieldset'>
              <FormLabel component='legend'>Age</FormLabel>
              <FormGroup row>
                {getAges().map((age) => (
                  <FormControlLabel key={age} label={capitalize(age)} control={
                    <Checkbox checked={formState.age?.includes(age)} onChange={handleMultiCheckboxChange('age', age)} />
                  } />
                ))}
              </FormGroup>
            </FormControl>
          </Grid>

          <Grid item xs={12}>
            <FormControl component='fieldset'>
              <FormLabel component='legend'>Media Type</FormLabel>
              <FormGroup row>
                {getMediaTypes().map((type) => (
                  <FormControlLabel key={type} label={capitalize(type)} control={
                    <Checkbox checked={formState.media_type?.includes(type)} onChange={handleMultiCheckboxChange('media_type', type)} />
                  } />
                ))}
              </FormGroup>
            </FormControl>
          </Grid>

          <Grid item xs={12}>
            <FormControlLabel label='Teacher Version' control={
              <Checkbox checked={formState.teacher_version} onChange={handleCheckboxChange('teacher_version')} />
            } />
          </Grid>

          {!isLesson(formState) && (<Grid item xs={12}>
            <TextField label='Content' name='content' value={formState.content} onChange={handleChange} required={!isLesson(formState)} fullWidth multiline rows={12} />
            {formState.content && (<Stack direction='row' justifyContent='flex-end' sx={{ mt: 1 }}>
              <Button variant='outlined' startIcon={<PreviewIcon />} onClick={() => setOpenPreviewDialog(true)}>Preview</Button>
            </Stack>)}
          </Grid>)}

          {isLesson(formState) && (<Grid item xs={12}>
            <TextField label='Raw description' name='description' value={formState.description} onChange={handleChange} fullWidth multiline required={isLesson(formState)} rows={8} />
          </Grid>)}

          <Grid item xs={12}>
            <TextField label='Photo ID' name='photo.id' value={formState.photo ? formState.photo.id : ''} onChange={handleChange} fullWidth />
          </Grid>

          {formState.photo?.urls && (<Grid item xs={12}>
            <Box component='section'>
              <figure style={{ margin: 0, width: '50%' }}>
                <img src={sanitizeUrl(formState.photo?.urls?.raw)} loading='lazy' alt={formState.title} style={{ maxWidth: '100%', objectFit: 'cover', borderRadius: 4 }} />
              </figure>
            </Box>
          </Grid>)}

          <Grid item xs={12}>
            <Stack spacing={3} direction='row' justifyContent='flex-end' >
              <Button type='submit' variant='contained' color='success'>Submit</Button>
              <Button variant='outlined'>Cancel</Button>
              {updateMode && (<Button variant='outlined' color='error' startIcon={<DeleteIcon />} onClick={() => setOpenDeleteDialog(true)}>Delete</Button>)}

            </Stack>
          </Grid>

        </Grid>
      </form>

      <MaterialDeleteDialog open={openDeleteDialog} id={material?.id ? material?.id : ''} onClose={(o) => setOpenDeleteDialog(o)} />
      <MaterialPreviewDialog open={openPreviewDialog} material={formState} onClose={(o) => setOpenPreviewDialog(o)} />
    </Container>
  );
}

export default MaterialForm;
