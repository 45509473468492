const local = {
  name: 'local',
  canonical: 'http://localhost:3000',
  api: {
    url: 'https://api.eslfinder.cloud'
    //url: 'http://localhost:8080'
  },
  contact: {
    url: 'https://docs.google.com/forms/d/e/1FAIpQLSdJAf2d3cn9JH29W_gr5NKVgohOvmeIbRV6uRl8VnNpumB17A/viewform?embedded=true'
  },
  oidc: {
    authority: "https://cognito-idp.us-east-1.amazonaws.com/us-east-1_74YfW88Em",
    client_id: "3tuhl3b730edp9dsfn84u0icob",
    domain: "https://id.eslfinder.cloud",
  },
  gtm: {
    id: 'GTM-MR7TS4G4'
  },
  ads: {
    client: 'ca-pub-4291575708683317',
    sidebar: '2014641478',
    grid: '7789712330',
    article: '1568127020',
    horizontal: '4449233128'
  },
  flags: {
    auth_enabled: false
  }
};

const dev = {
  name: 'dev',
  canonical: 'https://www.eslfinder.cloud',
  api: {
    url: 'https://api.eslfinder.cloud'
  },
  contact: {
    url: 'https://docs.google.com/forms/d/e/1FAIpQLSdJAf2d3cn9JH29W_gr5NKVgohOvmeIbRV6uRl8VnNpumB17A/viewform?embedded=true'
  },
  oidc: {
    authority: "https://cognito-idp.us-east-1.amazonaws.com/us-east-1_74YfW88Em",
    client_id: "3tuhl3b730edp9dsfn84u0icob",
    domain: "https://id.eslfinder.cloud",
  },
  gtm: {
    id: 'GTM-MR7TS4G4'
  },
  ads: {
    client: 'ca-pub-4291575708683317',
    sidebar: '2014641478',
    grid: '7789712330',
    article: '1568127020',
    horizontal: '4449233128'
  },
  flags: {
    auth_enabled: false
  }
};

const prd = {
  name: 'prd',
  canonical: 'https://www.eslfinder.com',
  api: {
    url: 'https://api.eslfinder.com'
  },
  contact: {
    url: 'https://docs.google.com/forms/d/e/1FAIpQLSdaBomBKEAZ6oSlZ4jICVUu6B1vN7qWZ190BzzJ5wPkSFiqtQ/viewform?embedded=true'
  },
  oidc: {
    authority: "https://cognito-idp.us-east-1.amazonaws.com/us-east-1_iIw6ri54N",
    client_id: "1c8i9nlm475q7scj11f24t6m7u",
    domain: "https://id.eslfinder.com",
  },
  gtm: {
    id: 'GTM-5KX72LWK'
  },
  ads: {
    client: 'ca-pub-4291575708683317',
    sidebar: '2014641478',
    grid: '7789712330',
    article: '1568127020',
    horizontal: '4449233128'
  },
  flags: {
    auth_enabled: false
  }
};

function getConfig(env: string | undefined) {
  switch (env) {
    case 'prd': return prd;
    case 'dev': return dev;
    default: return local;
  }
}

const env = getConfig(process.env.REACT_APP_ENV);

export default env;
