import { Box, Container, Stack, Typography } from '@mui/material';
import { Helmet } from 'react-helmet-async';
import { Navigate, useParams } from 'react-router-dom';
import AdUnit from '../commons/components/ads/AdUnit';
import BuyMeACoffe from '../commons/components/BuyMeACoffe';
import Loading from '../commons/components/Loading';
import MarkdownPreview from '../commons/components/markdown/MarkdownPreview';
import env from '../commons/configs/env';
import { decodeMaterial } from '../commons/util/MaterialUtils';
import MaterialGrid from '../material/components/MaterialGrid';
import { useFetchMaterial } from '../material/hooks/useMaterialAPI';

function PostPage() {
  const { id } = useParams();
  const { data, isFetched, isLoading } = useFetchMaterial(id!);
  const material = data ? decodeMaterial(data) : data;

  if (isFetched && !material) {
    return <Navigate to='/not-found' />
  }

  if (isLoading) {
    return <Loading />
  }

  if (!material) {
    return <></>
  }

  const title = `${material.title} | ESL Finder`;
  const url = `${env.canonical}/blog/${material.id}`;
  const overview = material.overview ? material.overview : material.description;
  const description = `${overview}`.slice(0, 100)

  const photoParams = '&auto=format&w=1000&h=300&q=75&fit=crop';
  const photoUrl = material.photo?.urls?.raw + photoParams;

  return (
    <Container component='article' maxWidth='xl' sx={{ my: 4, }}>

      <Helmet prioritizeSeoTags>
        <title>{title}</title>
        <link rel='canonical' href={url} />
        <meta name='description' content={description} />
        <meta property='og:site_name' content='ESL Finder' />
        <meta property='og:title' content={title} />
        <meta property='og:url' content={url} />
        <meta property='og:description' content={description} />
        <meta property='og:image' content={photoUrl} />
        <meta property='og:type' content='website' />
      </Helmet>

      <Container maxWidth='md' component='section'>
        <MarkdownPreview material={material} />

        <Stack sx={{ alignItems: 'center', my: 4, p: 5, bgcolor: '#efefef' }}>
          <Typography variant='body1' sx={{ my: 2, textAlign: 'justify' }}>
            <em><b>Enjoying your visit?</b> We'd love your support to keep bringing valuable content and resources. Any contribution, big or small, makes a real difference. Thanks!</em>
          </Typography>
          <BuyMeACoffe />
        </Stack>
      </Container>

      <Box component='section' sx={{ mt: 4, mb: 8, textAlign: 'center' }}>
        <AdUnit slot={env.ads.horizontal} />
      </Box>

      <Box component='section' sx={{ my: 6 }}>
        <div id='disqus_thread'></div>
      </Box>

      <Box component='section' sx={{ my: 6 }}>
        <MaterialGrid />
      </Box>
    </Container>

  );
}

export default PostPage;
