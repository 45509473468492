import { Box, Typography } from '@mui/material';
import { Helmet } from 'react-helmet-async';
import env from '../configs/env';
import { isAdvancedLessonsPage, isBeginnerLessonsPage, isBusinessLessonsPage, isEslCurriculumPage, isFreeLessonsPage, isGeneralLessonsPage, isGrammarLessonsPage, isIntermediateLessonsPage, isLessonsForKidsPage, isLessonsForTeenagersPage } from '../util/PageUtils';

export default function SearchPageContext() {

  const context = () => {
    if (isFreeLessonsPage()) {
      return {
        helmet: {
          title: 'Free English lessons | ESL Finder',
          description: 'Search for free English lesson plans and access high-quality free English lessons and start learning today.'
        },
        header: {
          title: <>Free English Lessons <span style={{ color: 'yellow' }}>for</span> English teachers</>,
          description: <>Explore <span style={{ color: 'yellow' }}>free</span> EFL/ESL materials for <span style={{ color: 'yellow' }}>English classes</span>.</>,
        }
      };
    } else if (isGeneralLessonsPage()) {
      return {
        helmet: {
          title: 'General English lessons | ESL Finder',
          description: 'Search for general English lesson plans. Covering reading, writing, listening, and speaking for all proficiency levels.'
        },
        header: {
          title: <>General English lessons <span style={{ color: 'yellow' }}>for</span> English teachers</>,
          description: <>Explore EFL/ESL materials for <span style={{ color: 'yellow' }}>general English classes</span>.</>,
        }
      };
    } else if (isGrammarLessonsPage()) {
      return {
        helmet: {
          title: 'Grammar English lessons | ESL Finder',
          description: 'Search for grammar English lesson plans. Clear explanations and practical exercises to enhance your language accuracy.'
        },
        header: {
          title: <>Grammar English lessons <span style={{ color: 'yellow' }}>for</span> English teachers</>,
          description: <>Explore EFL/ESL materials for <span style={{ color: 'yellow' }}>grammar English classes</span>.</>,
        }
      };
    } else if (isBusinessLessonsPage()) {
      return {
        helmet: {
          title: 'Business English lessons | ESL Finder',
          description: 'Search for business English lesson plans. Develop communication skills for the corporate environment.'
        },
        header: {
          title: <>Business English lessons <span style={{ color: 'yellow' }}>for</span> English teachers</>,
          description: <>Explore EFL/ESL materials for <span style={{ color: 'yellow' }}>business English classes</span>.</>,
        }
      };
    } else if (isBeginnerLessonsPage()) {
      return {
        helmet: {
          title: 'Beginner English lessons | ESL Finder',
          description: 'Search for beginner English lesson plans. Simple and effective methods to build a strong foundation.'
        },
        header: {
          title: <>Beginner English lessons <span style={{ color: 'yellow' }}>for</span> English teachers</>,
          description: <>Explore EFL/ESL materials for <span style={{ color: 'yellow' }}>beginner English classes</span>.</>,
        }
      };
    } else if (isIntermediateLessonsPage()) {
      return {
        helmet: {
          title: 'Intermediate English lessons | ESL Finder',
          description: 'Search for intermediate English lesson plans. Enhance your grammar, vocabulary, and conversational skills.'
        },
        header: {
          title: <>Intermediate English lessons <span style={{ color: 'yellow' }}>for</span> English teachers</>,
          description: <>Explore EFL/ESL materials for <span style={{ color: 'yellow' }}>intermediate English classes</span>.</>,
        }
      };
    } else if (isAdvancedLessonsPage()) {
      return {
        helmet: {
          title: 'Advanced English lessons | ESL Finder',
          description: 'Search for advanced English lesson plans. In-depth grammar, extensive vocabulary, and advanced conversation practice.'
        },
        header: {
          title: <>Advanced English lessons <span style={{ color: 'yellow' }}>for</span> English teachers</>,
          description: <>Explore EFL/ESL materials for <span style={{ color: 'yellow' }}>advanced English classes</span>.</>,
        }
      };
    } else if (isLessonsForKidsPage()) {
      return {
        helmet: {
          title: 'English lessons for kids | ESL Finder',
          description: 'Search for English lesson plans for kids. Make learning English fun and engaging for children.'
        },
        header: {
          title: <>English lessons for kids <span style={{ color: 'yellow' }}>for</span> English teachers</>,
          description: <>Explore EFL/ESL materials for <span style={{ color: 'yellow' }}>kid's English classes</span>.</>,
        }
      };
    } else if (isLessonsForTeenagersPage()) {
      return {
        helmet: {
          title: 'English lessons for teenagers | ESL Finder',
          description: 'Search for English lesson plans for teenagers, focusing on relevant topics and interactive learning methods.'
        },
        header: {
          title: <>English lessons for teenagers <span style={{ color: 'yellow' }}>for</span> English teachers</>,
          description: <>Explore EFL/ESL materials for <span style={{ color: 'yellow' }}>teenagers English classes</span>.</>,
        }
      };
    } else if (isEslCurriculumPage()) {
      return {
        helmet: {
          title: 'English lessons by Crystal Clear ESL | ESL Finder',
          description: 'Search for English lesson plans for teenagers, focusing on relevant topics and interactive learning methods.'
        },
        header: {
          title: <>English lessons by <span style={{ color: 'yellow' }}>Crystal Clear ESL</span></>,
          description: <>A fully interactive, professionally designed 
                          English language curriculum specifically created for freelance online educators. Brought to you by ESL Finder.</>,
        }
      };
    }

    return {
      helmet: {
        title: 'English lessons | ESL Finder',
        description: 'Search for English lesson plans. Enhance your language skills with completed English lessons for all levels. Learn grammar, vocabulary, and conversation effectively.'
      },
      header: {
        title: <>English Lessons <span style={{ color: 'yellow' }}>for</span> English Teachers</>,
        description: <>Explore EFL/ESL materials for <span style={{ color: 'yellow' }}>English classes</span>.</>,
      }
    };
  }

  return (<>
    <Helmet>
      <title>{context().helmet.title}</title>
      <link rel='canonical' href={`${env.canonical}${window.location.pathname}`} />
      <meta name='description' content={context().helmet.description} />
    </Helmet>

    <Box component='section' width='100%' sx={{ background: '#2d877e', textAlign: 'center', pt: 2, pb: 5 }}>
      <Typography variant='h1' sx={{ mb: 1, color: 'white' }}>{context().header.title}</Typography>
      <Typography component='p' sx={{ color: '#ebebeb', fontStyle: 'italic', maxWidth: '960px', mx: 'auto' }}>
        {context().header.description}
        <br />
        Search for specific content or utilize the filter options on the left to narrow down your search.
      </Typography>
    </Box>
  </>);
}
