import BookmarkIcon from '@mui/icons-material/Bookmark';
import BookmarkBorderOutlinedIcon from '@mui/icons-material/BookmarkBorderOutlined';
import { Stack, Typography } from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';
import { useAuth } from 'react-oidc-context';
import { useLoginModal } from '../../commons/components/login/LoginProvider';
import { Material } from '../../commons/openapi';
import { hasSession } from '../../commons/util/JwtUtils';
import { useFindInteractionByUser } from '../../interaction/hooks/useInteractionAPI';
import { useFavorite } from '../hooks/useMaterialAPI';

interface Props {
  readonly material: Material,
}

function MaterialBookmark({ material }: Props) {

  const queryClient = useQueryClient();
  const auth = useAuth();
  const { openLoginModal } = useLoginModal();
  const { data } = useFindInteractionByUser([]);

  const favorite = data?.favorites ? Array.from(data.favorites).includes(material.id) : false;

  const onSuccess = () => {
    queryClient.invalidateQueries({ queryKey: ['materials-summary'] });
    queryClient.invalidateQueries({ queryKey: ['interactions'] });
  }

  const onError = (error: any) => {
  }

  const favoriteMaterial = useFavorite(onSuccess, onError);

  const onClick = () => {
    if (hasSession(auth)) {
      favoriteMaterial.mutate(material.id);
    } else {
      openLoginModal();
    }
  };

  const iconStyle = { fill: '#2d877e', transform: 'scale(1.3)' };

  return <Stack direction='row' gap={1} onClick={onClick} sx={{ cursor: 'pointer' }}>
    {favorite ?
      <>
        <BookmarkIcon style={iconStyle} />
        <Typography fontWeight='bold'>Remove from bookmarks</Typography>
      </>
      :
      <>
        <BookmarkBorderOutlinedIcon style={iconStyle} />
        <Typography fontWeight='bold'>Bookmark this lesson</Typography>
      </>
    }
  </ Stack>;
}

export default MaterialBookmark;
