import { Box, Button, Container, Grid, Link, Typography } from '@mui/material';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import { Helmet } from 'react-helmet-async';
import { useNavigate } from 'react-router-dom';
import env from '../commons/configs/env';


function AboutPage() {
  const navigate = useNavigate();

  return (
    <Container maxWidth='xl' sx={{ textAlign: 'center' }}>

      <Helmet>
        <title>About | ESL Finder</title>
        <link rel='canonical' href={`${env.canonical}/about`} />
        <meta name='description' content='Find out what our plans are to help teachers and educators looking for English lessons.' />
      </Helmet>

      <Box width='100%' sx={{ textAlign: 'center', my: 4 }}>
        <Typography sx={{ maxWidth: 800, mx: 'auto' }} variant='h1' fontSize={30} fontWeight={'bold'} mt={7} mb={5}>Welcome to ESL Finder! We're here to make your ESL teaching journey easier and more effective.</Typography>
        <br />
        <Card sx={{ maxWidth: 1176, m: 'auto', boxShadow: 'none' }}>
          <CardMedia component={'video'} image={'video-esl-finder.mp4'} poster='video_cover.jpg' controls />
        </Card>
      </Box>

      <Typography variant='body1' component='article' sx={{ textAlign: 'justify', maxWidth: '1176px', mx: 'auto', my: 6 }}>
        <p>
          Teaching is undoubtedly a challenging profession, and we recognize the importance of streamlining the process, allowing you to concentrate on your true passion — <b>teaching</b>.
        </p>
        <p>
          Every teacher, from English teachers to those who teach other subjects, often struggles to find the right materials for their classes. It's like a puzzle where they need to find the missing pieces.
        </p>
        <p>
          Our goal is to make your life easier. Here's what we're committed to:
        </p>
        <ul>
          <li><b>Extensive Content Repository</b>: Our platform boasts a vast repository of teaching materials curated for diverse subjects and educational levels. It doesn't matter if you teach kids, teens or adults, you'll find a rich collection of resources tailored to your specific needs.</li>
          <li><b>Advanced Search Functionality</b>: We understand that time is of the essence for educators. Our advanced search features enable you to quickly pinpoint the materials that align with your curriculum, saving you valuable time and effort. It's like having a personal assistant dedicated to finding the missing pieces of your instructional puzzle.</li>
          <li><b>User-Friendly Interface</b>: Navigating our platform is a breeze. We've designed an intuitive and organized interface to ensure that you can seamlessly explore, filter, and access materials without unnecessary complications. Our commitment to keeping things tidy extends to every corner of our platform.</li>
          <li><b>Time-Efficient Curation</b>: We value your time and understand the demands of lesson planning. Our platform is equipped with time-saving features, such as pre-curated collections. Check out our <Link color='primary.main' underline='hover' href='/blog'>Blog</Link>.</li>
        </ul>
        <p>In essence, our commitment goes beyond simplicity — it's about empowering educators with the tools they need to excel in the classroom. Join us in redefining the way teaching materials are discovered, organized, and utilized, and experience a new era in education.</p>
        <Box component='section' sx={{ my: 6, textAlign: 'center' }}>
          <Button variant='contained' disableElevation={true} sx={{ fontSize: 18, bgcolor: '#2d877e' }} size='large' onClick={() => navigate({ pathname: '/english-lessons' })}>Discover English lessons</Button>
        </Box>
        <hr />

      </Typography>


      <Box maxWidth='1176px' sx={{ textAlign: 'center', mx: 'auto', my: 6 }}>
        <Typography variant='h1' sx={{ mb: 4 }}>Meet our team</Typography>

        <Grid container spacing={10} alignItems='center' justifyContent='center' className='about-description'>
          <Grid item xs={12} md={6}>
            <img src='/david.jpg' width={480} loading='lazy' alt='David B. G. Dutra' />
            <h2>David</h2>
            <p>
              David, with his extensive background in web development, is the technical wizard of the pair. He is the mastermind behind the seamless functionality and user-friendly interface of the website. His expertise ensures that the platform operates without a hitch, providing a mdooth experience for users. But David is not just a tech aficionado; he is also a savvy businessperson with a keen eye for results.
            </p>
          </Grid>
          <Grid item xs={12} md={6} sx={{ mt: '-29px' }}>
            <img src='../will.jpg' width={480} loading='lazy' alt='Willian C. Souza' />
            <h2>Will</h2>
            <p>
              Will, as an English teacher, is the educational specialist of the team, with a deep understanding of the learning process and a passion for helping people. Will's contributions lie in the content and lesson curation, ensuring that the material is not only accurate and comprehensive but also engaging and easy to understand.
            </p>
          </Grid>
        </Grid>
      </Box>

    </Container>
  );
}

export default AboutPage;
