import AttachFileIcon from '@mui/icons-material/AttachFile';
import FeedIcon from '@mui/icons-material/Feed';
import OndemandVideoIcon from '@mui/icons-material/OndemandVideo';
import PeopleIcon from '@mui/icons-material/People';
import SavingsIcon from '@mui/icons-material/Savings';
import SignalCellularAltIcon from '@mui/icons-material/SignalCellularAlt';
import { Box, Stack, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Material } from '../../commons/openapi';

import AudioFileIcon from '@mui/icons-material/AudioFile';
import CoPresentIcon from '@mui/icons-material/CoPresent';
import { agesToString, hasAudio, hasMediaType, hasTeacherVersion, hasVideo, isBusiness, isFree, isGrammar, levelsToString, mediaTypesToString } from '../../commons/util/MaterialUtils';

interface Props {
  readonly material: Material;
}

function MaterialCharacteristic({ material }: Props) {

  const iconStyle = { fill: '#2d877e' };

  const ageIcon = <PeopleIcon fontSize='large' style={iconStyle} />;
  const planIcon = <SavingsIcon fontSize='large' style={iconStyle} />;
  const teacherVersionIcon = <AttachFileIcon fontSize='large' style={iconStyle} />;
  const mediaIcon = hasVideo(material) ? <OndemandVideoIcon fontSize='large' style={iconStyle} /> : hasAudio(material) ? <AudioFileIcon fontSize='large' style={iconStyle} /> : <CoPresentIcon fontSize='large' style={iconStyle} />;
  const levelIcon = <SignalCellularAltIcon fontSize='large' style={iconStyle} />;
  const categorySubtitle = isGrammar(material) ? 'Grammar - Focused practice for language structure and proficiency enhancement' : isBusiness(material) ? 'Business - Targeted learning for professional communication and workplace skills' : 'General - Versatile instruction for everyday communication and vocabulary expansion';

  const StyledIconsBox = styled(Box)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    gap: '10px 5px',
    marginBottom: 15,
    fontSize: 14,
    backgroundColor: '#efefef',
    padding: '20px 30px',
  }));

  return (
    <Stack spacing={2} sx={{ my: 2 }}>

      <StyledIconsBox>

        <Stack direction='row' alignItems='center' spacing={2} maxWidth={'45%'}>
          {levelIcon}
          <Stack>
            <Typography variant='subtitle1' color={'#2d877e'} sx={{ fontWeight: 'bold' }}>Customized learning</Typography>
            <Typography variant='subtitle2'>Enhance learning outcomes for <b>{levelsToString(material)}</b> level students</Typography>
          </Stack>
        </Stack>

        <Stack direction='row' alignItems='center' spacing={2} maxWidth={'45%'}>
          {ageIcon}
          <Stack>
            <Typography variant='subtitle1' color={'#2d877e'} sx={{ fontWeight: 'bold' }}>Tailored by age groups</Typography>
            <Typography variant='subtitle2'>Engaging material for <b>{agesToString(material)}</b></Typography>
          </Stack>
        </Stack>

        {hasTeacherVersion(material) && (
          <Stack direction='row' alignItems='center' spacing={2} maxWidth={'45%'}>
            {teacherVersionIcon}
            <Stack>
              <Typography variant='subtitle1' color={'#2d877e'} sx={{ fontWeight: 'bold' }}>Empowering Educators</Typography>
              <Typography variant='subtitle2'>Providing resources for both <b>teacher and student</b></Typography>
            </Stack>
          </Stack>
        )}

        <Stack direction='row' alignItems='center' spacing={2} maxWidth={'45%'}>
          <FeedIcon fontSize='large' style={iconStyle} />
          <Stack>
            <Typography variant='subtitle1' color={'#2d877e'} sx={{ fontWeight: 'bold' }}>Focused instruction</Typography>
            <Typography variant='subtitle2'>{categorySubtitle}</Typography>
          </Stack>
        </Stack>

        {hasMediaType(material) && (
          <Stack direction='row' alignItems='center' spacing={2} maxWidth={'45%'}>
            {mediaIcon}
            <Stack>
              <Typography variant='subtitle1' color={'#2d877e'} sx={{ fontWeight: 'bold' }}>Multi-Media learning</Typography>
              <Typography variant='subtitle2'>Unlocking learning potential with {mediaTypesToString(material)}</Typography>
            </Stack>
          </Stack>
        )}

        {isFree(material) && (
          <Stack direction='row' alignItems='center' spacing={2} maxWidth={'45%'}>
            {planIcon}
            <Stack>
              <Typography variant='subtitle1' color={'#2d877e'} sx={{ fontWeight: 'bold' }}>Accessible Education</Typography>
              <Typography variant='subtitle2'><b>Free!</b> Quality learning resources at no cost</Typography>
            </Stack>
          </Stack>
        )}

      </StyledIconsBox>
    </Stack>

  );
}

export default MaterialCharacteristic;
