/* tslint:disable */
/* eslint-disable */
/**
 * eslfinder-service API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0-SNAPSHOT
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { AccessPlan } from '../model';
// @ts-ignore
import { Age } from '../model';
// @ts-ignore
import { Category } from '../model';
// @ts-ignore
import { Constraint } from '../model';
// @ts-ignore
import { JsonPatchList } from '../model';
// @ts-ignore
import { Level } from '../model';
// @ts-ignore
import { Material } from '../model';
// @ts-ignore
import { MaterialNew } from '../model';
// @ts-ignore
import { MaterialPage } from '../model';
// @ts-ignore
import { MaterialSort } from '../model';
// @ts-ignore
import { MediaType } from '../model';
// @ts-ignore
import { SyncMode } from '../model';
/**
 * MaterialResourceApi - axios parameter creator
 * @export
 */
export const MaterialResourceApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create material
         * @param {MaterialNew} [materialNew] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createMaterial: async (materialNew?: MaterialNew, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/material`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication SecurityScheme required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(materialNew, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete material
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteMaterial: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteMaterial', 'id', id)
            const localVarPath = `/material/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication SecurityScheme required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Favorite material
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        favoriteMaterial: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('favoriteMaterial', 'id', id)
            const localVarPath = `/material/{id}/favorite`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication SecurityScheme required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Find full material detail
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findFullMaterial: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('findFullMaterial', 'id', id)
            const localVarPath = `/material/{id}/full`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication SecurityScheme required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Find material detail
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findMaterial: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('findMaterial', 'id', id)
            const localVarPath = `/material/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Find summary materials
         * @param {Set<AccessPlan>} [accessPlan] 
         * @param {Set<Age>} [age] 
         * @param {Set<Category>} [category] 
         * @param {boolean} [favorites] 
         * @param {Set<Level>} [level] 
         * @param {Set<MediaType>} [mediaType] 
         * @param {number} [page] 
         * @param {number} [perPage] 
         * @param {Set<MaterialSort>} [sort] 
         * @param {Set<string>} [source] 
         * @param {Array<FindSummaryMaterialsStatusEnum>} [status] 
         * @param {boolean} [teacherVersion] 
         * @param {string} [term] 
         * @param {Array<FindSummaryMaterialsTypeEnum>} [type] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findSummaryMaterials: async (accessPlan?: Set<AccessPlan>, age?: Set<Age>, category?: Set<Category>, favorites?: boolean, level?: Set<Level>, mediaType?: Set<MediaType>, page?: number, perPage?: number, sort?: Set<MaterialSort>, source?: Set<string>, status?: Array<FindSummaryMaterialsStatusEnum>, teacherVersion?: boolean, term?: string, type?: Array<FindSummaryMaterialsTypeEnum>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/material`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (accessPlan) {
                localVarQueryParameter['access_plan'] = Array.from(accessPlan);
            }

            if (age) {
                localVarQueryParameter['age'] = Array.from(age);
            }

            if (category) {
                localVarQueryParameter['category'] = Array.from(category);
            }

            if (favorites !== undefined) {
                localVarQueryParameter['favorites'] = favorites;
            }

            if (level) {
                localVarQueryParameter['level'] = Array.from(level);
            }

            if (mediaType) {
                localVarQueryParameter['media_type'] = Array.from(mediaType);
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (perPage !== undefined) {
                localVarQueryParameter['per_page'] = perPage;
            }

            if (sort) {
                localVarQueryParameter['sort'] = Array.from(sort);
            }

            if (source) {
                localVarQueryParameter['source'] = Array.from(source);
            }

            if (status) {
                localVarQueryParameter['status'] = status;
            }

            if (teacherVersion !== undefined) {
                localVarQueryParameter['teacher_version'] = teacherVersion;
            }

            if (term !== undefined) {
                localVarQueryParameter['term'] = term;
            }

            if (type) {
                localVarQueryParameter['type'] = type;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Patch material
         * @param {string} id 
         * @param {JsonPatchList} [jsonPatchList] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchMaterial: async (id: string, jsonPatchList?: JsonPatchList, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('patchMaterial', 'id', id)
            const localVarPath = `/material/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication SecurityScheme required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(jsonPatchList, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Redirect material
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        redirectMaterial: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('redirectMaterial', 'id', id)
            const localVarPath = `/material/{id}/redirect`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Sync materials
         * @param {SyncMode} [mode] 
         * @param {Array<MaterialNew>} [materialNew] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        syncMaterials: async (mode?: SyncMode, materialNew?: Array<MaterialNew>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/material/sync`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication SecurityScheme required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (mode !== undefined) {
                localVarQueryParameter['mode'] = mode;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(materialNew, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update material
         * @param {string} id 
         * @param {MaterialNew} [materialNew] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateMaterial: async (id: string, materialNew?: MaterialNew, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateMaterial', 'id', id)
            const localVarPath = `/material/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication SecurityScheme required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(materialNew, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MaterialResourceApi - functional programming interface
 * @export
 */
export const MaterialResourceApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = MaterialResourceApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create material
         * @param {MaterialNew} [materialNew] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createMaterial(materialNew?: MaterialNew, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Material>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createMaterial(materialNew, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete material
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteMaterial(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteMaterial(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Favorite material
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async favoriteMaterial(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.favoriteMaterial(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Find full material detail
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findFullMaterial(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Material>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findFullMaterial(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Find material detail
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findMaterial(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Material>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findMaterial(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Find summary materials
         * @param {Set<AccessPlan>} [accessPlan] 
         * @param {Set<Age>} [age] 
         * @param {Set<Category>} [category] 
         * @param {boolean} [favorites] 
         * @param {Set<Level>} [level] 
         * @param {Set<MediaType>} [mediaType] 
         * @param {number} [page] 
         * @param {number} [perPage] 
         * @param {Set<MaterialSort>} [sort] 
         * @param {Set<string>} [source] 
         * @param {Array<FindSummaryMaterialsStatusEnum>} [status] 
         * @param {boolean} [teacherVersion] 
         * @param {string} [term] 
         * @param {Array<FindSummaryMaterialsTypeEnum>} [type] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findSummaryMaterials(accessPlan?: Set<AccessPlan>, age?: Set<Age>, category?: Set<Category>, favorites?: boolean, level?: Set<Level>, mediaType?: Set<MediaType>, page?: number, perPage?: number, sort?: Set<MaterialSort>, source?: Set<string>, status?: Array<FindSummaryMaterialsStatusEnum>, teacherVersion?: boolean, term?: string, type?: Array<FindSummaryMaterialsTypeEnum>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MaterialPage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findSummaryMaterials(accessPlan, age, category, favorites, level, mediaType, page, perPage, sort, source, status, teacherVersion, term, type, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Patch material
         * @param {string} id 
         * @param {JsonPatchList} [jsonPatchList] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async patchMaterial(id: string, jsonPatchList?: JsonPatchList, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<JsonPatchList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.patchMaterial(id, jsonPatchList, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Redirect material
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async redirectMaterial(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Material>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.redirectMaterial(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Sync materials
         * @param {SyncMode} [mode] 
         * @param {Array<MaterialNew>} [materialNew] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async syncMaterials(mode?: SyncMode, materialNew?: Array<MaterialNew>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Material>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.syncMaterials(mode, materialNew, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update material
         * @param {string} id 
         * @param {MaterialNew} [materialNew] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateMaterial(id: string, materialNew?: MaterialNew, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Material>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateMaterial(id, materialNew, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * MaterialResourceApi - factory interface
 * @export
 */
export const MaterialResourceApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = MaterialResourceApiFp(configuration)
    return {
        /**
         * 
         * @summary Create material
         * @param {MaterialNew} [materialNew] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createMaterial(materialNew?: MaterialNew, options?: any): AxiosPromise<Material> {
            return localVarFp.createMaterial(materialNew, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete material
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteMaterial(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteMaterial(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Favorite material
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        favoriteMaterial(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.favoriteMaterial(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Find full material detail
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findFullMaterial(id: string, options?: any): AxiosPromise<Material> {
            return localVarFp.findFullMaterial(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Find material detail
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findMaterial(id: string, options?: any): AxiosPromise<Material> {
            return localVarFp.findMaterial(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Find summary materials
         * @param {Set<AccessPlan>} [accessPlan] 
         * @param {Set<Age>} [age] 
         * @param {Set<Category>} [category] 
         * @param {boolean} [favorites] 
         * @param {Set<Level>} [level] 
         * @param {Set<MediaType>} [mediaType] 
         * @param {number} [page] 
         * @param {number} [perPage] 
         * @param {Set<MaterialSort>} [sort] 
         * @param {Set<string>} [source] 
         * @param {Array<FindSummaryMaterialsStatusEnum>} [status] 
         * @param {boolean} [teacherVersion] 
         * @param {string} [term] 
         * @param {Array<FindSummaryMaterialsTypeEnum>} [type] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findSummaryMaterials(accessPlan?: Set<AccessPlan>, age?: Set<Age>, category?: Set<Category>, favorites?: boolean, level?: Set<Level>, mediaType?: Set<MediaType>, page?: number, perPage?: number, sort?: Set<MaterialSort>, source?: Set<string>, status?: Array<FindSummaryMaterialsStatusEnum>, teacherVersion?: boolean, term?: string, type?: Array<FindSummaryMaterialsTypeEnum>, options?: any): AxiosPromise<MaterialPage> {
            return localVarFp.findSummaryMaterials(accessPlan, age, category, favorites, level, mediaType, page, perPage, sort, source, status, teacherVersion, term, type, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Patch material
         * @param {string} id 
         * @param {JsonPatchList} [jsonPatchList] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchMaterial(id: string, jsonPatchList?: JsonPatchList, options?: any): AxiosPromise<JsonPatchList> {
            return localVarFp.patchMaterial(id, jsonPatchList, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Redirect material
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        redirectMaterial(id: string, options?: any): AxiosPromise<Material> {
            return localVarFp.redirectMaterial(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Sync materials
         * @param {SyncMode} [mode] 
         * @param {Array<MaterialNew>} [materialNew] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        syncMaterials(mode?: SyncMode, materialNew?: Array<MaterialNew>, options?: any): AxiosPromise<Array<Material>> {
            return localVarFp.syncMaterials(mode, materialNew, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update material
         * @param {string} id 
         * @param {MaterialNew} [materialNew] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateMaterial(id: string, materialNew?: MaterialNew, options?: any): AxiosPromise<Material> {
            return localVarFp.updateMaterial(id, materialNew, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * MaterialResourceApi - interface
 * @export
 * @interface MaterialResourceApi
 */
export interface MaterialResourceApiInterface {
    /**
     * 
     * @summary Create material
     * @param {MaterialNew} [materialNew] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MaterialResourceApiInterface
     */
    createMaterial(materialNew?: MaterialNew, options?: AxiosRequestConfig): AxiosPromise<Material>;

    /**
     * 
     * @summary Delete material
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MaterialResourceApiInterface
     */
    deleteMaterial(id: string, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @summary Favorite material
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MaterialResourceApiInterface
     */
    favoriteMaterial(id: string, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @summary Find full material detail
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MaterialResourceApiInterface
     */
    findFullMaterial(id: string, options?: AxiosRequestConfig): AxiosPromise<Material>;

    /**
     * 
     * @summary Find material detail
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MaterialResourceApiInterface
     */
    findMaterial(id: string, options?: AxiosRequestConfig): AxiosPromise<Material>;

    /**
     * 
     * @summary Find summary materials
     * @param {Set<AccessPlan>} [accessPlan] 
     * @param {Set<Age>} [age] 
     * @param {Set<Category>} [category] 
     * @param {boolean} [favorites] 
     * @param {Set<Level>} [level] 
     * @param {Set<MediaType>} [mediaType] 
     * @param {number} [page] 
     * @param {number} [perPage] 
     * @param {Set<MaterialSort>} [sort] 
     * @param {Set<string>} [source] 
     * @param {Array<FindSummaryMaterialsStatusEnum>} [status] 
     * @param {boolean} [teacherVersion] 
     * @param {string} [term] 
     * @param {Array<FindSummaryMaterialsTypeEnum>} [type] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MaterialResourceApiInterface
     */
    findSummaryMaterials(accessPlan?: Set<AccessPlan>, age?: Set<Age>, category?: Set<Category>, favorites?: boolean, level?: Set<Level>, mediaType?: Set<MediaType>, page?: number, perPage?: number, sort?: Set<MaterialSort>, source?: Set<string>, status?: Array<FindSummaryMaterialsStatusEnum>, teacherVersion?: boolean, term?: string, type?: Array<FindSummaryMaterialsTypeEnum>, options?: AxiosRequestConfig): AxiosPromise<MaterialPage>;

    /**
     * 
     * @summary Patch material
     * @param {string} id 
     * @param {JsonPatchList} [jsonPatchList] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MaterialResourceApiInterface
     */
    patchMaterial(id: string, jsonPatchList?: JsonPatchList, options?: AxiosRequestConfig): AxiosPromise<JsonPatchList>;

    /**
     * 
     * @summary Redirect material
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MaterialResourceApiInterface
     */
    redirectMaterial(id: string, options?: AxiosRequestConfig): AxiosPromise<Material>;

    /**
     * 
     * @summary Sync materials
     * @param {SyncMode} [mode] 
     * @param {Array<MaterialNew>} [materialNew] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MaterialResourceApiInterface
     */
    syncMaterials(mode?: SyncMode, materialNew?: Array<MaterialNew>, options?: AxiosRequestConfig): AxiosPromise<Array<Material>>;

    /**
     * 
     * @summary Update material
     * @param {string} id 
     * @param {MaterialNew} [materialNew] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MaterialResourceApiInterface
     */
    updateMaterial(id: string, materialNew?: MaterialNew, options?: AxiosRequestConfig): AxiosPromise<Material>;

}

/**
 * MaterialResourceApi - object-oriented interface
 * @export
 * @class MaterialResourceApi
 * @extends {BaseAPI}
 */
export class MaterialResourceApi extends BaseAPI implements MaterialResourceApiInterface {
    /**
     * 
     * @summary Create material
     * @param {MaterialNew} [materialNew] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MaterialResourceApi
     */
    public createMaterial(materialNew?: MaterialNew, options?: AxiosRequestConfig) {
        return MaterialResourceApiFp(this.configuration).createMaterial(materialNew, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete material
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MaterialResourceApi
     */
    public deleteMaterial(id: string, options?: AxiosRequestConfig) {
        return MaterialResourceApiFp(this.configuration).deleteMaterial(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Favorite material
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MaterialResourceApi
     */
    public favoriteMaterial(id: string, options?: AxiosRequestConfig) {
        return MaterialResourceApiFp(this.configuration).favoriteMaterial(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Find full material detail
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MaterialResourceApi
     */
    public findFullMaterial(id: string, options?: AxiosRequestConfig) {
        return MaterialResourceApiFp(this.configuration).findFullMaterial(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Find material detail
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MaterialResourceApi
     */
    public findMaterial(id: string, options?: AxiosRequestConfig) {
        return MaterialResourceApiFp(this.configuration).findMaterial(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Find summary materials
     * @param {Set<AccessPlan>} [accessPlan] 
     * @param {Set<Age>} [age] 
     * @param {Set<Category>} [category] 
     * @param {boolean} [favorites] 
     * @param {Set<Level>} [level] 
     * @param {Set<MediaType>} [mediaType] 
     * @param {number} [page] 
     * @param {number} [perPage] 
     * @param {Set<MaterialSort>} [sort] 
     * @param {Set<string>} [source] 
     * @param {Array<FindSummaryMaterialsStatusEnum>} [status] 
     * @param {boolean} [teacherVersion] 
     * @param {string} [term] 
     * @param {Array<FindSummaryMaterialsTypeEnum>} [type] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MaterialResourceApi
     */
    public findSummaryMaterials(accessPlan?: Set<AccessPlan>, age?: Set<Age>, category?: Set<Category>, favorites?: boolean, level?: Set<Level>, mediaType?: Set<MediaType>, page?: number, perPage?: number, sort?: Set<MaterialSort>, source?: Set<string>, status?: Array<FindSummaryMaterialsStatusEnum>, teacherVersion?: boolean, term?: string, type?: Array<FindSummaryMaterialsTypeEnum>, options?: AxiosRequestConfig) {
        return MaterialResourceApiFp(this.configuration).findSummaryMaterials(accessPlan, age, category, favorites, level, mediaType, page, perPage, sort, source, status, teacherVersion, term, type, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Patch material
     * @param {string} id 
     * @param {JsonPatchList} [jsonPatchList] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MaterialResourceApi
     */
    public patchMaterial(id: string, jsonPatchList?: JsonPatchList, options?: AxiosRequestConfig) {
        return MaterialResourceApiFp(this.configuration).patchMaterial(id, jsonPatchList, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Redirect material
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MaterialResourceApi
     */
    public redirectMaterial(id: string, options?: AxiosRequestConfig) {
        return MaterialResourceApiFp(this.configuration).redirectMaterial(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Sync materials
     * @param {SyncMode} [mode] 
     * @param {Array<MaterialNew>} [materialNew] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MaterialResourceApi
     */
    public syncMaterials(mode?: SyncMode, materialNew?: Array<MaterialNew>, options?: AxiosRequestConfig) {
        return MaterialResourceApiFp(this.configuration).syncMaterials(mode, materialNew, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update material
     * @param {string} id 
     * @param {MaterialNew} [materialNew] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MaterialResourceApi
     */
    public updateMaterial(id: string, materialNew?: MaterialNew, options?: AxiosRequestConfig) {
        return MaterialResourceApiFp(this.configuration).updateMaterial(id, materialNew, options).then((request) => request(this.axios, this.basePath));
    }
}

/**
 * @export
 */
export const FindSummaryMaterialsStatusEnum = {
    Draft: 'DRAFT',
    Published: 'PUBLISHED'
} as const;
export type FindSummaryMaterialsStatusEnum = typeof FindSummaryMaterialsStatusEnum[keyof typeof FindSummaryMaterialsStatusEnum];
/**
 * @export
 */
export const FindSummaryMaterialsTypeEnum = {
    Lesson: 'LESSON',
    Post: 'POST',
    Worksheet: 'WORKSHEET'
} as const;
export type FindSummaryMaterialsTypeEnum = typeof FindSummaryMaterialsTypeEnum[keyof typeof FindSummaryMaterialsTypeEnum];
