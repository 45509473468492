import { Button, Card, CardContent, CardMedia, Typography } from '@mui/material';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

function PubCard() {

  const overviewStile = {
    lineHeight: 1.4,
    margin: '10px 0 20px',
    height: '77px',
    overflow: 'hidden',
    display: '-webkit-box',
  };
  
  const cardStyle = {
    width: 360,
    height: 500,
    border: '1px solid #ffdd00',
    position: 'relative',
    boxShadow: 'none !important',
    '&:hover': {
      transition: 'all .3s ease',
      opacity: 0.9,
    }
  };
  
  const titleStyle = {
    fontWeight: 'bold',
    height: '45px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    marginBottom: '7px',
  };
  
  return (
    <div>
    <Card sx={cardStyle}>
      
      <CardMedia component='img' width='250px' height='250px' sx={{ maxWidth: 250, objectFit: 'cover', margin: '3px auto' }} image='crystal_eslfinder_card.jpg' alt='crystal clear + eslfinder' />

      <CardContent sx={{ textAlign: 'left' }}>
        <Typography variant='h4' sx={titleStyle}>Crystal Clear English lessons</Typography>
        <Typography variant='caption' fontSize={14} fontWeight={'bold'} height={'22px'} display={'block'} color={'#2d877e'}>
        Special Collection
      </Typography>
        <Typography variant='body2' color='text.secondary' sx={overviewStile}>A fully interactive, professionally designed English language curriculum specifically created for freelance online educators. Brought to you by ESL Finder.</Typography>
        <Button variant='contained' color='secondary' href='/english-lessons/esl-curriculum' sx={{ float: 'right', background: '#ffdd00', color: '#555' }}>View Lessons <ArrowForwardIosIcon style={{ fill: '#555' }} /></Button>
      </CardContent>

    </Card>
    </div>
  );
}

export default PubCard;
