import { Link, Typography } from '@mui/material';
import { Material } from '../../openapi/model';
import { getThemes } from '../../util/MaterialUtils';


interface Props {
  readonly material: Material,
  readonly clickableTheme?: boolean
}

function ThemeFilter({ material, clickableTheme }: Props) {
  if (clickableTheme) {
    return (
      <Typography variant='caption' fontSize={14} fontWeight={'bold'} height={'22px'} display={'block'}>
        {getThemes(material).map((theme) => (
          <Link key={`filter-${material.id}-${theme}`} sx={{ cursor: 'pointer' }} href={`?term=${theme}`} underline='none' color='secondary' mr={2}>{theme}</Link>
        ))}
      </Typography>
    );
  } else {
    return (
      <Typography variant='caption' fontSize={14} fontWeight={'bold'} height={'22px'} display={'block'}>
        {getThemes(material).map((theme) => (
          <Link key={`filter-${material.id}-${theme}`} underline='none' color='secondary' mr={2}>{theme}</Link>
        ))}
      </Typography>
    );
  }
}

export default ThemeFilter;
