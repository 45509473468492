import SignalCellularAltIcon from '@mui/icons-material/SignalCellularAlt';
import { Material } from '../../../commons/openapi';

interface Props {
  readonly material: Material,
}

function LevelIcon({ material }: Props) {
  const levels = material.level ? Array.from(material.level) : []
  const title = levels.join(', ');
  const label = levels.length > 2 ? 'Mixed Levels' : title;

  if (levels.length > 0) {
    return <span style={{ display: 'flex', alignItems: 'center' }}>
      <SignalCellularAltIcon style={{ fill: '#2d877e', marginRight: '5px' }} />
      {label}
    </span>;
  }

  return <></>

}

export default LevelIcon;
