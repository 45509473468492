import { Facebook, Instagram, LinkedIn } from '@mui/icons-material';
import { Box, Container, Grid, Link, Stack, Typography } from '@mui/material';


export default function Footer() {
  return (
    <Container maxWidth='xl'>

      <Grid container spacing={5}>

        <Grid item xs={12} sm={4}>
          <Typography variant='h4' mb={2} gutterBottom>About Us</Typography>
          <Typography variant='body2' mb={1}>We help teachers and educators to save time and energy while searching for English lessons plans.</Typography>
          <Link variant='body2' color='primary.main' underline='hover' href='/about' aria-label='Learn more about us'>See more about us</Link>
        </Grid>

        <Grid item xs={12} sm={4}>
          <Typography variant='h4' mb={2} gutterBottom>Support</Typography>
          <Stack spacing={1}>
            <Link variant='body2' color='primary.main' underline='hover' href='/contact' aria-label='Contact us'>Contact</Link>
            <Link variant='body2' color='primary.main' underline='hover' href='/faq' aria-label='Visit our FAQ page'>FAQ</Link>
          </Stack>
        </Grid>

        <Grid item xs={12} sm={4}>
          <Typography variant='h4' mb={2} gutterBottom>Follow Us</Typography>
          <Stack direction='row' spacing={2}>
            <Link target='_blank' href='https://www.linkedin.com/company/esl-finder' aria-label='Follow us on LinkedIn'><LinkedIn /></Link>
            <Link target='_blank' href='https://www.instagram.com/esl.finder' aria-label='Visit our Instagram'><Instagram /></Link>
            <Link target='_blank' href='https://www.facebook.com/eslfinder/' aria-label='Visit our Facebook page'><Facebook /></Link>
          </Stack>
        </Grid>

      </Grid>

      <Box>
        <Typography variant='body2' color='text.primary' align='center' sx={{ mt: 4 }}>
          {'Copyright © '}
          <Link underline='hover' href='/'>ESL Finder</Link>
          {' '}
          {new Date().getFullYear()}
          {' - '}
          <Link underline='hover' href='/cookie-policy'>Cookie policy</Link>
          {' - '}
          <Link underline='hover' href='/privacy-policy'>Privacy policy</Link>
          {' '}
        </Typography>
      </Box>

    </Container>
  );
}
