import { Container } from '@mui/material';
import { Helmet } from 'react-helmet-async';
import env from '../commons/configs/env';


function ContactPage() {
  return (
    <Container component='article' maxWidth='xl' sx={{ my: 8 }}>

      <Helmet>
        <title>Contact | ESL Finder</title>
        <link rel='canonical' href={`${env.canonical}/contact`} />
        <meta name='description' content='Feel free to get in touch with us using our contact form.' />
      </Helmet>

      <iframe src={env.contact.url} title='Contact' width='100%' height='700' frameBorder={0} marginHeight={0} marginWidth={0}>Loading...</iframe>

    </Container>
  );
}

export default ContactPage;
