import { Container, styled } from '@mui/material';

const StyledBox = styled(Container)(({ theme }) => ({
  textAlign: 'center',
  paddingTop: theme.spacing(5),
}));


function Loading() {
  return (
    <StyledBox maxWidth='xl'>
      <img src='/loading.gif' loading='lazy' alt='loading...' />
    </StyledBox>
  );
}

export default Loading;
