import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
import DrawIcon from '@mui/icons-material/Draw';
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';
import { Box } from '@mui/material';
import { Material } from '../../openapi';
import { isBusiness, isGeneral, isGrammar } from '../../util/MaterialUtils';
import { capitalize } from '../../util/String';


interface Props {
  readonly material: Material,
}

function CategoryIcon({ material }: Props) {
  const iconStyle = { fill: '#2d877e', marginRight: '5px' };

  const label = capitalize(material.category);

  return (
    <Box>
      <span style={{ display: 'flex', alignItems: 'center' }}>
        {isBusiness(material) && <BusinessCenterIcon style={iconStyle} />}
        {isGrammar(material) && <DrawIcon style={iconStyle} />}
        {isGeneral(material) && <LibraryBooksIcon style={iconStyle} />}
        {label}
      </span>
    </Box>
  );
}

export default CategoryIcon;
