import { AccessPlan, Age, Category, Level, MaterialSort, MediaType, Status, Type } from '../../commons/openapi';
import { buildSearchParamsFromPath } from '../../commons/util/PageUtils';

export interface MaterialFilter {
  term?: string;
  teacherVersion?: string[];
  plan?: AccessPlan[];
  age?: Age[];
  category?: Category[];
  level?: Level[];
  media?: MediaType[];
  sort?: MaterialSort[];
  type?: Type[];
  source?: string[];
  page?: number;
  perPage?: number;
  status?: Status[];
  favorites?: string[];
}

function convertMaterialFilter(searchParams: URLSearchParams, param: string, value: any): boolean | undefined {
  return searchParams.get(param)?.split(',').includes(value);
}

export function buildMaterialFilter(searchParams: URLSearchParams): MaterialFilter {

  searchParams = buildSearchParamsFromPath(searchParams);

  return {
    term: searchParams.get('term') || undefined,
    category: Object.values(Category).filter(value => convertMaterialFilter(searchParams, 'category', value)),
    level: Object.values(Level).filter(value => convertMaterialFilter(searchParams, 'level', value)),
    age: Object.values(Age).filter(value => convertMaterialFilter(searchParams, 'age', value)),
    media: Object.values(MediaType).filter(value => convertMaterialFilter(searchParams, 'media', value)),
    plan: Object.values(AccessPlan).filter(value => convertMaterialFilter(searchParams, 'plan', value)),
    teacherVersion: Object.values(['YES', 'NO']).filter(value => convertMaterialFilter(searchParams, 'teacher_version', value)),
    favorites: Object.values(['YES', 'NO']).filter(value => convertMaterialFilter(searchParams, 'favorites', value)),
    status: Object.values(Status).filter(value => convertMaterialFilter(searchParams, 'status', value)),
    source: searchParams.get('source')?.split(','),
  };
}
