import LogoutIcon from '@mui/icons-material/Logout';
import { AppBar, Avatar, Button, IconButton, Link, Menu, MenuItem, Stack, Typography } from '@mui/material';
import { useState } from 'react';
import { useAuth } from 'react-oidc-context';
import env from '../configs/env';
import { useLoginModal } from './login/LoginProvider';

export default function NavBar() {

  const auth = useAuth();
  const profile = auth.user?.profile;
  const { openLoginModal } = useLoginModal();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const navItems = [
    { name: 'Home', path: '/' },
    { name: 'English lessons', path: '/english-lessons' },
    { name: 'Blog', path: '/blog' },
    { name: 'About', path: '/about' },
    { name: 'Contact', path: '/contact' },
  ];

  const navItemStyle = { textDecoration: 'none', color: 'white', p: 1.8, ml: 2, textAlign: 'center', '&:hover': { borderBottom: '7px solid white', } };

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleLogout = () => {
    setAnchorEl(null);
    auth.removeUser();
    //auth.signoutRedirect();
    window.location.href = `${env.oidc.domain}/logout?client_id=${env.oidc.client_id}&logout_uri=${window.location.origin}&redirect_uri=${window.location.origin}&response_type=code`;
  };

  const isActive = (itemPath: string) => {
    return window.location.pathname === itemPath;
  }

  return (
    <AppBar component='nav' className='navmenu' position='static' sx={{ py: 3, boxShadow: 'none', bgcolor: '#2d877e' }}>
      <Stack maxWidth='xl' direction={{ sm: 'column', md: 'row' }} sx={{ m: 'auto', gap: { sm: 0, md: 24 } }} >
        <Link href='/'>
          <img src='/logo_eslfinder.png' alt='logo ESL Finder' loading='lazy' width={225} />
        </Link>
        <Stack direction={{ sm: 'column', md: 'row' }}>
          {navItems.map((item) => (
            <Link key={item.name} href={item.path} sx={{ ...navItemStyle, borderBottom: isActive(item.path) ? '7px solid white' : '' }}>{item.name}</Link>
          ))}
        </Stack>
        {auth.isAuthenticated && auth.user ?
          (<>
            <IconButton onClick={handleMenu} sx={{ p: 0, mt: 0.5 }}>
              <Avatar alt={profile?.name} src={profile?.picture} />
            </IconButton>
            <Menu anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={() => setAnchorEl(null)}>
              <MenuItem onClick={handleLogout}>
                <Stack direction='row' gap={1} justifyContent='center'>
                  <LogoutIcon />
                  <Typography variant='body1'>Sign out</Typography>
                </Stack>
              </MenuItem>
            </Menu>
          </>)
          :
          <Button sx={{ p: 0, my: 2 }} style={{color: 'yellow'}} startIcon={<LogoutIcon />} onClick={() => openLoginModal()}>Sign In / Join</Button>
        }
      </Stack>
    </AppBar>
  );
}
