export function convertToLocalTimezone(dateString: string): string {
  const date = new Date(dateString);
  const timezoneOffset = date.getTimezoneOffset() * 60000;
  const localDate = new Date(date.getTime() - timezoneOffset);

  return localDate.toISOString().slice(0, -1);
}

export function toYYYYMMDD(dateStr: string | undefined) {
  if (!dateStr) {
    return dateStr;
  }

  const date = new Date(dateStr);
  const y = date.getUTCFullYear();
  const m = String(date.getUTCMonth() + 1).padStart(2, '0');
  const d = String(date.getUTCDate()).padStart(2, '0');

  return `${y}-${m}-${d}`;
}

export function toDDMMYYYY(dateStr: string | undefined) {
  if (!dateStr) {
    return dateStr;
  }

  const date = new Date(dateStr);
  const y = date.getUTCFullYear();
  const m = String(date.getUTCMonth() + 1).padStart(2, '0');
  const d = String(date.getUTCDate()).padStart(2, '0');

  return `${d}/${m}/${y}`;
}
