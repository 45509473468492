import { Box, Grid, Link, Stack } from '@mui/material';
import { Fragment, useEffect } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useLocation } from 'react-router-dom';
import BuyMeACoffe from '../../commons/components/BuyMeACoffe';
import Empty from '../../commons/components/Empty';
import Loading from '../../commons/components/Loading';
import SearchPageContext from '../../commons/components/SearchPageContext';
import Share from '../../commons/components/Share';
import AdCard from '../../commons/components/ads/AdCard';
import AdUnit from '../../commons/components/ads/AdUnit';
import FavoriteFilter from '../../commons/components/filter/FavoriteFilter';
import SideBarFilter from '../../commons/components/filter/SideBarFilter';
import TermFilter from '../../commons/components/filter/TermFilter';
import env from '../../commons/configs/env';
import { Material, MaterialPage, Type } from '../../commons/openapi';
import { isEslCurriculumPage, isMainLessonsPage } from '../../commons/util/PageUtils';
import MaterialCard from '../../material/components/MaterialCard';
import { useInfinityFetchSummaryMaterials } from '../../material/hooks/useMaterialAPI';
import PubCard from '../../commons/components/pub/PubCard';


function LessonSearchPage() {
  const { pathname } = useLocation();
  const { data, isFetching, hasNextPage, fetchNextPage } = useInfinityFetchSummaryMaterials(Type.Lesson);
  const materials = data?.pages.reduce((materials: Material[], page: MaterialPage) => {
    return [...materials, ...page.materials];
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  const renderMore = (): JSX.Element => {
    return <Box width='100%' sx={{ display: 'flex', justifyContent: 'center', my: 1 }}>
      <Link href='#' underline='hover' variant='inherit' onClick={() => fetchNextPage()}>More</Link>
    </Box>
  }

  const renderGridItem = (material: Material, i: number): JSX.Element => {
    if(i === 0 && isMainLessonsPage()) {
      return <Fragment key={`${material.id}-fragment`}>
        <PubCard key={`${material.id}-pub`} />
        <MaterialCard key={material.id} material={material} clickableTheme={true} />
      </Fragment>
    }
    if (i >= 12 && i % 12 === 0) {
      return <Fragment key={`${material.id}-fragment`}>
        <AdCard key={`${material.id}-ad`} />
        <MaterialCard key={material.id} material={material} clickableTheme={true} />
      </Fragment>
    }

    return <MaterialCard key={material.id} material={material} clickableTheme={true} />
  }

  return (
    <Box component='article'>

      <SearchPageContext />

      <Stack direction={{ xs: 'column', sm: 'row' }}>
        <Box component='aside' minWidth={320} sx={{ m: 1 }}>
          <SideBarFilter />
          <BuyMeACoffe />
          <AdUnit slot={env.ads.sidebar} />
        </Box>

        <Box component='section' width='100%' mx={2}>
          {isEslCurriculumPage() &&
            <Box my={2} textAlign={'center'} m={4} maxWidth={'90%'}>
              <img src='/crystal_eslfinder.jpg' alt='Crystal + ESL Finder' loading='lazy' width={911} style={{ maxWidth: '95%' }} />
            </Box>
          }

          <Box my={2}>
            <TermFilter />
          </Box>

          <Stack direction={{ sm: 'column', md: 'row' }} alignItems='center' justifyContent='space-between' my={4} maxWidth='1900px'>
            {/* ainda não sei se é legal mostrar isso <Typography variant='h3' sx={{flex: 'auto'}}>X Lessons found</Typography> */}
            <FavoriteFilter />
            <Share />
          </Stack>

          <InfiniteScroll style={{ width: '100%' }} dataLength={materials ? materials.length : 0} next={() => fetchNextPage()} hasMore={hasNextPage ? hasNextPage : false} loader={<></>}>
            <Grid container sx={{ justifyContent: 'left' }} gap={3}>
              {materials?.map(renderGridItem)}
            </Grid>
          </InfiniteScroll>
          {!isFetching && materials?.length === 0 && (<Empty />)}
          {!isFetching && hasNextPage && renderMore()}
          {isFetching && (<Loading />)}
        </Box>
      </Stack>

    </Box>
  );
}

export default LessonSearchPage;
