import { ArrowForward } from '@mui/icons-material';
import { Box, Container, Grid, Stack, Typography } from '@mui/material';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import { Helmet } from 'react-helmet-async';
import env from '../commons/configs/env';
import { AccessPlan, Age, Category, Level } from '../commons/openapi';
import MaterialGrid from '../material/components/MaterialGrid';

function HomePage() {

  return (
    <Container component='article' maxWidth='xl'>

      <Helmet>
        <title>Find English lesson plans and worksheets easily | ESL Finder</title>
        <link rel='canonical' href={env.canonical} />
        <meta name='description' content='Welcome to ESL Finder! Here you can save time and energy while searching for English lessons.' />
      </Helmet>

      <Grid container spacing={2} sx={{ my: 5, textAlign: 'left' }} >
        <Grid container item xs={12} md={5} direction='column' >
          <Typography variant='h1' sx={{ mb: 2, mt: '60px' }}>Find <strong>English lessons</strong> and <strong>worksheets</strong> easily.</Typography>
          <Typography variant='h2' sx={{ mb: 2 }}>Your Comprehensive Resource Hub for <strong>Teaching Materials</strong>.</Typography>
          <Box sx={{ my: 1 }}>
            <Typography component='p' sx={{ my: 1 }}>
              Imagine a Skyscanner for English lessons – that's ESL Finder. We streamline your prep time by helping you quickly discover the perfect teaching materials. Watch our 2-minute demo to see how effortlessly you can plan your classes!
              <ArrowForward sx={{ mb: '-5px' }} color='secondary' />
            </Typography>
          </Box>
        </Grid>
        <Grid container item xs={12} md={7} direction='column' >
          <Card sx={{ maxWidth: 1176, m: 'auto', boxShadow: 'none' }}>
            <CardMedia component={'video'} image={'video-esl-finder.mp4'} poster='video_cover.jpg' controls />
          </Card>
        </Grid>
      </Grid>

      <Stack gap={8} my={8}>
        
        <Box component='section'>
          <MaterialGrid materialFilter={{ source: ['esl-curriculum'], perPage: 4 }} />
        </Box>

        <Box component='section'>
          <MaterialGrid materialFilter={{ plan: [AccessPlan.Free], perPage: 4 }} />
        </Box>

        <Box component='section'>
          <MaterialGrid materialFilter={{ level: [Level.A1, Level.A2], perPage: 4 }} />
        </Box>

        <Box component='section'>
          <MaterialGrid materialFilter={{ level: [Level.B1, Level.B2], perPage: 4 }} />
        </Box>

        <Box component='section'>
          <MaterialGrid materialFilter={{ level: [Level.C1, Level.C2], perPage: 4 }} />
        </Box>

        <Box component='section'>
          <MaterialGrid materialFilter={{ category: [Category.Business], perPage: 4 }} />
        </Box>

        <Box component='section'>
          <MaterialGrid materialFilter={{ category: [Category.Grammar], perPage: 4 }} />
        </Box>

        <Box component='section'>
          <MaterialGrid materialFilter={{ category: [Category.General], perPage: 4 }} />
        </Box>

        <Box component='section'>
          <MaterialGrid materialFilter={{ age: [Age.Kids], perPage: 4 }} />
        </Box>

        <Box component='section'>
          <MaterialGrid materialFilter={{ age: [Age.Teenagers], perPage: 4 }} />
        </Box>
      </Stack>

    </Container>
  );
}

export default HomePage;
