import { jwtDecode } from "jwt-decode";
import { AuthContextProps } from "react-oidc-context";

function getPayload(auth: AuthContextProps) {
  if (!auth?.user?.access_token) {
    return
  }

  return jwtDecode<{ 'cognito:groups': string[] }>(auth.user.access_token);
}

export const hasSession = (auth: AuthContextProps): boolean => {
  if (auth.isAuthenticated && !auth?.user?.expired) {
    return true;
  }

  return false;
}

export const hasAdminPermission = (auth: AuthContextProps): boolean => {
  const payload: { 'cognito:groups': string[]; } | undefined = getPayload(auth);

  if (!auth.isAuthenticated || auth?.user?.expired || !payload) {
    return false;
  }

  const roles = ['admin'];
  const userRoles = payload['cognito:groups'];

  return userRoles.some((r: string) => roles.includes(r));
}
