import { Adsense } from '@ctrl/react-adsense';
import { Box } from '@mui/material';
import env from '../../configs/env';

interface Props {
  readonly slot: string;
  readonly format?: string;
  readonly layout?: string;
  readonly align?: string;
}

function AdUnit({ slot, format, layout, align }: Props) {
  const textAlign = align ? align : 'center';

  return (
    <Box sx={{ mt: 2, width: '100%', border: '0px solid #2d877e', textAlign: textAlign }}>
      <Adsense client={env.ads.client} slot={slot} format={format ? format : 'auto'} layout={layout} />
    </Box>
  );
}

export default AdUnit;
