import { sanitizeUrl } from '@braintree/sanitize-url';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { IconButton, Stack, Typography } from '@mui/material';
import { FacebookIcon, FacebookShareButton, RedditIcon, RedditShareButton, TelegramIcon, TelegramShareButton, TwitterIcon, TwitterShareButton, WhatsappIcon, WhatsappShareButton } from 'react-share';

export default function Share() {
  const url = sanitizeUrl(window.location.href);

  const title = 'Check out this selection of English Lessons that I found on ESL Finder!';

  const copyMessage = url;
  const hashtag = 'ESLFinder';
  const size = 37;
  const round = true;

  const onCopy = () => {
    navigator.clipboard.writeText(`${copyMessage}`);
  }

  return (
    <Stack id='share' direction={{ sm: 'column', md: 'row' }} justifyContent='space-between' gap={2}>

      <Stack direction='row' alignItems='center' gap={2}>
        <Typography>Share it with your colleagues</Typography>
        <ArrowDownwardIcon color='secondary' sx={{ display: { xs: 'block', md: 'none' } }} />
        <ArrowForwardIcon color='secondary' sx={{ display: { xs: 'none', md: 'block' } }} />
      </Stack>

      <Stack id='share' direction='row' gap={2}>
        <FacebookShareButton url={url} title={title} hashtag={hashtag}>
          <FacebookIcon size={size} round={round} />
        </FacebookShareButton>

        <TwitterShareButton url={url} title={title} hashtags={[hashtag]}>
          <TwitterIcon size={size} round={round} />
        </TwitterShareButton>

        <WhatsappShareButton url={url} title={title}>
          <WhatsappIcon size={size} round={round} />
        </WhatsappShareButton>

        <TelegramShareButton url={url} title={title}>
          <TelegramIcon size={size} round={round} />
        </TelegramShareButton>

        <RedditShareButton url={url} title={title}>
          <RedditIcon size={size} round={round} />
        </RedditShareButton>

        <IconButton onClick={onCopy} color='success' aria-label='Copy link'>
          <ContentCopyIcon />
        </IconButton>
      </Stack>

    </Stack>
  );
}
