import { Box, Typography } from '@mui/material';


function Empty() {
  return (
    <Box sx={{ textAlign: 'center', my: 4, }}>
      <img src='/empty-64.png' loading='lazy' alt='No English material found.' />
      <Typography sx={{ mt: 2 }}>
        No English material found. Please try using a different filter.
      </Typography>
    </Box>
  );
}

export default Empty;
