import { Container } from '@mui/material';
import { AxiosError } from 'axios';
import { enqueueSnackbar } from 'notistack';
import { Helmet } from 'react-helmet-async';
import { useNavigate } from 'react-router-dom';
import queryClient from '../../commons/configs/queryClient';
import { Constraint, Material, MaterialNew } from '../../commons/openapi';
import { getPath } from '../../commons/util/MaterialUtils';
import MaterialForm from '../components/MaterialForm';
import { useCreateMaterial } from '../hooks/useMaterialAPI';


function MaterialNewPage() {
  const navigate = useNavigate();

  const onError = (error: AxiosError) => {
    const constraint = error.response?.data as Constraint;
    const message = constraint.message ? constraint.message : 'Something went wrong.';

    enqueueSnackbar(message, { variant: 'error' });
  }

  const onSuccess = (material: Material) => {
    queryClient.invalidateQueries({ queryKey: ['material-full', material.id] });
    enqueueSnackbar('The material was created successfully.', { variant: 'success' });
    navigate(`/${getPath(material)}/${material.id}`);
  }

  const createLessonMutation = useCreateMaterial(onSuccess, onError);

  const onSubmit = async (lessonNew: MaterialNew) => {
    createLessonMutation.mutate(lessonNew);
  };

  return (
    <Container maxWidth='xl' sx={{ my: 4, maxWidth: '1264px !important' }}>
      <Helmet>
        <title>Create Material | ESL Finder</title>
        <meta name='robots' content='noindex, nofollow' />
      </Helmet>

      <MaterialForm onSubmit={onSubmit} />
    </Container>
  );
}

export default MaterialNewPage;
