import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ClearIcon from '@mui/icons-material/Clear';
import { Box, Button, Stack, Typography } from '@mui/material';
import { useAuth } from 'react-oidc-context';
import { useSearchParams } from 'react-router-dom';
import { hasAdminPermission } from '../../util/JwtUtils';
import { isFreeLessonsPage, shouldHideAgeField, shouldHideCategoryField, shouldHideLevelField } from '../../util/PageUtils';
import ChipFilter from './ChipFilter';

function FilterSideBar() {
  const auth = useAuth();
  const filters = ['category', 'level', 'age', 'media', 'plan', 'teacher_version', 'status', 'source'];
  const [searchParams, setSearchParams] = useSearchParams();

  const onClearAll = () => {
    filters.forEach(f => searchParams.delete(f));
    setSearchParams(searchParams);
  }

  return (
    <Stack>

      <Stack direction='row' gap={1} alignItems='center' my={1} mx={1}>
        <Typography>Try applying some filters</Typography>
        <ArrowDownwardIcon color='secondary' />
      </Stack>

      <Box>
        {!shouldHideCategoryField() && <ChipFilter label='Category' description='Choose one or more to filter by category' param='category' />}
        {!shouldHideLevelField() && <ChipFilter label='Level' description='Select one or more to filter by level' param='level' />}
        {!shouldHideAgeField() && <ChipFilter label='Age' description='Toggle for kids and/or teenagers' param='age' />}
        <ChipFilter label='Media' description='Filter by "slides" to find classes ideal for online learning' param='media' />
        {!isFreeLessonsPage() && <ChipFilter label='Plan' description='Filter by pricing' param='plan' />}
        <ChipFilter label='Teacher Version' description='Filter for classes with a teacher version' param='teacher_version' />
        {hasAdminPermission(auth) && <ChipFilter label='Status' description='Filter by type' param='status' />}

        <Button variant='text' size='small' color='secondary' startIcon={<ClearIcon />} sx={{ ml: 1, mt: 1 }} onClick={onClearAll}>Clear All</Button>
      </Box>

    </Stack>
  );
}

export default FilterSideBar;
