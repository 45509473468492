import CloseIcon from '@mui/icons-material/Close';
import { Box, Container, Dialog, DialogContent, DialogTitle, IconButton } from '@mui/material';
import { useEffect, useState } from 'react';
import MarkdownPreview from '../../commons/components/markdown/MarkdownPreview';
import { Material, MaterialNew } from '../../commons/openapi';

interface Props {
  readonly material: Material | MaterialNew,
  readonly open: boolean,
  readonly onClose: (open: boolean) => void
}

function MaterialPreviewDialog({ material, open, onClose }: Props) {
  const [visible, setVisible] = useState(false);

  const handleCloseDialog = () => {
    onClose(false);
    setVisible(false);
  };

  useEffect(() => {
    setVisible(open);
  }, [open]);

  return (
    <Dialog fullWidth maxWidth='lg' open={visible} onClose={handleCloseDialog}>
      <DialogTitle>
        <Box display='flex' alignItems='center'>
          <Box flexGrow={1}>Preview</Box>
          <Box>
            <IconButton onClick={handleCloseDialog}>
              <CloseIcon />
            </IconButton>
          </Box>
        </Box>
      </DialogTitle>
      <DialogContent>
        <Container maxWidth='md' component='section'>
          <MarkdownPreview material={material} />
        </Container>
      </DialogContent>
    </Dialog>
  );
}

export default MaterialPreviewDialog;
