import { Box, Button, Grid, Link, Stack, Typography } from '@mui/material';
import Loading from '../../commons/components/Loading';
import { AccessPlan, Age, Category, Level, Material } from '../../commons/openapi';
import { MaterialFilter } from '../hooks/MaterialFilter';
import { useFetchSummaryMaterials } from '../hooks/useMaterialAPI';
import MaterialCard from './MaterialCard';

interface Props {
  readonly materialFilter?: MaterialFilter,
}

export default function MaterialGrid({ materialFilter }: Props) {
  const { data, isFetching } = useFetchSummaryMaterials(materialFilter ? materialFilter : { perPage: 4 });

  let obj = {
    title: 'English lessons',
    description: <></>,
    path: '/english-lessons'
  };

  if (materialFilter?.plan?.includes(AccessPlan.Free)) {
    obj = {
      title: 'Free English lessons',
      description: <Typography component='p'>Enhance your teaching toolkit with our extensive collection of <Link color={'#2d877e'} href='/free-english-lessons'>free English lessons</Link>. Access high-quality, ready-to-use materials designed to engage your students at all levels. Save time on lesson planning while delivering effective, enjoyable classes.</Typography>,
      path: '/free-english-lessons'
    }
  }

  if (materialFilter?.category?.includes(Category.General)) {
    obj = {
      title: 'General English lessons',
      description: <Typography component='p'>Deliver well-rounded language instruction with our versatile <Link color={'#2d877e'} href='/general-english-lessons'>general English lessons</Link>. Covering reading, writing, listening, and speaking for all proficiency levels, these resources help you create balanced, comprehensive English classes suitable for various learning goals.</Typography>,
      path: '/general-english-lessons'
    }
  }

  if (materialFilter?.category?.includes(Category.Business)) {
    obj = {
      title: 'Business English lessons',
      description: <Typography component='p'>Prepare your students for professional success with our targeted <Link color={'#2d877e'} href='/business-english-lessons'>business English lessons</Link>. Find materials focusing on corporate vocabulary, email writing, presentation skills, and business etiquette. Help your learners gain confidence in international business communications.</Typography>,
      path: '/business-english-lessons'
    }
  }

  if (materialFilter?.category?.includes(Category.Grammar)) {
    obj = {
      title: 'Grammar English lessons',
      description: <Typography component='p'>Strengthen your grammar instruction with our focused <Link color={'#2d877e'} href='/grammar-english-lessons'>grammar English lessons</Link>. From basic structures to advanced rules, find clear explanations and practical exercises to help your students master English grammar. Improve their writing accuracy and speaking precision.</Typography>,
      path: '/grammar-english-lessons'
    }
  }

  if (materialFilter?.age?.includes(Age.Kids)) {
    obj = {
      title: 'English lessons for kids',
      description: <Typography component='p'>Make your young learners' classes fun and effective with our <Link color={'#2d877e'} href='/english-lessons-for-kids'>English lessons for kids</Link>. Discover colorful materials, engaging activities, and age-appropriate content designed to capture children's imagination while building their English skills from an early age.</Typography>,
      path: '/english-lessons-for-kids'
    }
  }

  if (materialFilter?.age?.includes(Age.Teenagers)) {
    obj = {
      title: 'English lessons for teenagers',
      description: <Typography component='p'>Keep your teenage students motivated with our specially designed English lessons for adolescents. Find materials covering relevant topics, incorporating modern media, and focusing on practical language use. Help your teen learners develop strong English skills for academic and social situations.</Typography>,
      path: '/english-lessons-for-kids'
    }
  }

  if (materialFilter?.level?.includes(Level.A1)) {
    obj = {
      title: 'Beginner English lessons',
      description: <Typography component='p'>Lay a strong foundation for your students with our comprehensive <Link color={'#2d877e'} href='/beginner-english-lessons'>beginner English lessons</Link>. Find resources to teach essential grammar, introduce basic vocabulary, and develop fundamental conversational skills. Perfect for new learners or those needing a refresher.</Typography>,
      path: '/beginner-english-lessons'
    }
  }

  if (materialFilter?.level?.includes(Level.B1)) {
    obj = {
      title: 'Intermediate English lessons',
      description: <Typography component='p'>Challenge your progressing students with our carefully crafted <Link color={'#2d877e'} href='/intermediate-english-lessons'>intermediate English lessons</Link>. Discover materials to reinforce complex grammar concepts, expand vocabulary range, and improve conversational fluency. Ideal for preparing learners for more advanced language use.</Typography>,
      path: '/intermediate-english-lessons'
    }
  }

  if (materialFilter?.level?.includes(Level.C1)) {
    obj = {
      title: 'Advanced English lesson',
      description: <Typography component='p'>Push your top students further with our <Link color={'#2d877e'} href='/advanced-english-lessons'>advanced English lesson</Link>. Access resources covering nuanced grammar points, sophisticated vocabulary, and high-level conversation topics. Equip your learners with the skills to express complex ideas and understand subtle language nuances.</Typography>,
      path: '/advanced-english-lessons'
    }
  }

  if (materialFilter?.source?.includes('esl-curriculum')) {
    obj = {
      title: 'Crystal Clear English lessons',
      description: <Typography component='p'>A fully interactive, professionally designed <Link color={'#2d877e'} href='/english-lessons/esl-curriculum'>English language curriculum</Link> specifically created for freelance online educators. 
      <br />
      Brought to you by ESL Finder.</Typography>,
      path: '/english-lessons/esl-curriculum'
    }
  }

  return (
    <Stack gap={4}>
      <Box component='section' width='100%' sx={{ background: '#f4f4f4', textAlign: 'center', pt: 5, pb: 8 }}>

        {materialFilter?.source?.includes('esl-curriculum') && (
          <Box mb={4}>
            <img src='/crystal_eslfinder.jpg' alt='Crystal + ESL Finder' loading='lazy' width={911} style={{ maxWidth: '95%' }} />
          </Box>
        )}

        <Typography variant='h2' sx={{ mb: 1, color: '#2d877e', fontWeight: 'bold' }}>{obj.title}</Typography>
        <Typography component='p' sx={{ color: '#3c3c3c', fontStyle: 'italic', mx: 'auto', mb: 5 }}>
          {obj.description}
        </Typography>
        {isFetching && (<Loading />)}
        {!isFetching && (
          <Grid container sx={{ justifyContent: 'center' }} gap={1.6}>
            {data?.materials.map((material: Material) => (
              <MaterialCard key={material.id} material={material} />
            ))}
          </Grid>
        )}
        <Button variant='contained' disableElevation={true} sx={{ fontSize: '16px', backgroundColor: 'secondary.main', borderRadius: '40px', mt: 6, px: '40px', py: '20px', }} href={obj.path}>Show all {obj.title}</Button>
      </Box>
    </Stack>
  );
}
