export function capitalize(phrase: string) {
  if (!phrase) {
    return phrase;
  }

  const words = phrase.replace('_', ' ')
    .toLocaleLowerCase()
    .split(' ');

  for (let i = 0; i < words.length; i++) {
    words[i] = words[i][0].toUpperCase() + words[i].substring(1);
  }
  return words.join(' ');
}
