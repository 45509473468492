import { Box } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import { QueryClientProvider } from '@tanstack/react-query';
import { SnackbarProvider } from 'notistack';
import { useEffect } from 'react';
import TagManager from 'react-gtm-module';
import { HelmetProvider } from 'react-helmet-async';
import { useAuth } from 'react-oidc-context';
import { BrowserRouter } from 'react-router-dom';
import Footer from './commons/components/Footer';
import LoginModal from './commons/components/login/LoginModal';
import { LoginProvider } from './commons/components/login/LoginProvider';
import NavBar from './commons/components/NavBar';
import AppRoutes from './commons/components/route/AppRoutes';
import env from './commons/configs/env';
import queryClient from './commons/configs/queryClient';
import { theme } from './commons/configs/theme';

function App() {
  const auth = useAuth();

  useEffect(() => {
    return auth.events.addAccessTokenExpiring(() => {
      if (window.confirm('You are about to be signed out due to inactivity. Press continue to stay signed in.')) {
        auth.signinSilent();
      }
    })
  }, [auth.events, auth.signinSilent]);

  useEffect(() => {
    TagManager.initialize({ gtmId: env.gtm.id });
  }, []);

  switch (auth.activeNavigator) {
    case 'signinSilent':
      return <>Signing you in...</>;
    case 'signoutRedirect':
      return <>Signing you out...</>;
  }

  if (auth.isLoading) {
    return <></>
  }

  return (
    <BrowserRouter>
      <ThemeProvider theme={theme}>
        <QueryClientProvider client={queryClient}>
          <HelmetProvider>
            <SnackbarProvider preventDuplicate maxSnack={3} autoHideDuration={5000} anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}>
              <LoginProvider>
                <Box>
                  <NavBar />
                  <AppRoutes />
                  <Box component='footer' sx={{ backgroundColor: 'grey.50', pb: 5, mt: 16 }}>
                    <Footer />
                  </Box>
                  <LoginModal />
                </Box>
              </LoginProvider>
            </SnackbarProvider>
          </HelmetProvider>
        </QueryClientProvider>
      </ThemeProvider>
    </BrowserRouter>
  );
}

export default App;
