import { Box } from '@mui/material';
import env from '../../configs/env';
import AdUnit from './AdUnit';

function AdCard() {
  return (
    <Box sx={{ width: 360, height: 480, alignContent: 'center', border: '0px solid' }} display='flex' justifyContent='center'>
      <AdUnit slot={env.ads.grid} />
    </Box>
  );
}

export default AdCard;
