import { sanitizeUrl } from '@braintree/sanitize-url';
import he from 'he';
import { AccessPlan, Age, Category, Level, Material, MaterialNew, MediaType, Status, Type } from '../openapi';

export function isMixedLevel(material: Material) {
  return material?.level && Array.from(material.level).length > 2;
}

export function isCLevel(material: Material) {
  const levels = material?.level ? Array.from(material.level) : [];
  return levels && (levels.includes(Level.C1) || levels.includes(Level.C2));
}

export function isBLevel(material: Material) {
  const levels = material?.level ? Array.from(material.level) : [];
  return levels && (levels.includes(Level.B1) || levels.includes(Level.B2));
}

export function isALevel(material: Material) {
  const levels = material?.level ? Array.from(material.level) : [];
  return levels && (levels.includes(Level.A1) || levels.includes(Level.A2));
}

export function isFree(material: Material): boolean {
  return material.access_plan === AccessPlan.Free;
}

export function isGeneral(material: Material): boolean {
  return material.category === Category.General;
}

export function isGrammar(material: Material): boolean {
  return material.category === Category.Grammar;
}

export function isBusiness(material: Material): boolean {
  return material.category === Category.Business;
}

export function hasMediaType(material: Material) {
  return material.media_type && Array.from(material.media_type).length > 0;
}

export function hasVideo(material: Material) {
  const types = material?.media_type ? Array.from(material.media_type) : [];
  return types.includes(MediaType.Video);
}

export function hasAudio(material: Material) {
  const types = material.media_type ? Array.from(material.media_type) : [];
  return types.includes(MediaType.Audio);
}

export function hasSlide(material: Material) {
  const types = material?.media_type ? Array.from(material.media_type) : [];
  return types.includes(MediaType.Slide);
}

export function hasTeacherVersion(material: Material): boolean {
  return material.teacher_version;
}

export function forAdult(material: Material) {
  const ages = material?.age ? Array.from(material.age) : [];
  return ages.includes(Age.Adults);
}

export function forTeens(material: Material) {
  const ages = material?.age ? Array.from(material.age) : [];
  return ages.includes(Age.Teenagers);
}

export function forKids(material: Material) {
  const ages = material?.age ? Array.from(material.age) : [];
  return ages.includes(Age.Kids);
}

export function levelsToString(material: Material) {
  const levels = material?.level ? Array.from(material.level) : [];
  return levels.join(', ');
}

export function agesToString(material: Material) {
  const ages = material?.age ? Array.from(material.age) : [];
  return ages.join(', ').toLocaleLowerCase();
}

export function mediaTypesToString(material: Material) {
  const mediaTypes = material?.media_type ? Array.from(material.media_type) : [Age.Adults];
  return mediaTypes.join(', ').toLocaleLowerCase();
}

export function getCategories() {
  return Array.from(Object.values(Category));
}

export function getLevels() {
  return Array.from(Object.values(Level));
}

export function getAges() {
  return Array.from(Object.values(Age));
}

export function getMediaTypes() {
  return Array.from(Object.values(MediaType));
}

export function getAccessPlans() {
  return Array.from(Object.values(AccessPlan));
}

export function getStatuses() {
  return Array.from(Object.values(Status));
}

export function getTypes() {
  return Array.from(Object.values(Type));
}

export function getThemes(material: Material): string[] {
  if (material.theme) {
    if (material.theme.includes('|')) {
      return material.theme.split('|').map(t => t.trim()).slice(0, 2);
    } else {
      return [material.theme.trim()];
    }
  } else {
    return [];
  }
}

export function isLesson(material: Material | MaterialNew): boolean {
  return material.type === Type.Lesson;
}

export function isPost(material: Material | MaterialNew): boolean {
  return material.type === Type.Post;
}

export function isWorksheet(material: Material | MaterialNew): boolean {
  return material.type === Type.Worksheet;
}

export function getPath(material: Material) {
  switch (material.type) {
    case Type.Post:
      return 'blog';
    case Type.Worksheet:
      return 'worksheet';
    default:
      return 'lesson';
  }
}

export function getPhoto(material: Material) {
  const photoParams = '&auto=format&w=600&h=400&q=75&fit=crop';

  const defaultPhoto = {
    author: 'Unseen Studio',
    profile: 'https://unsplash.com/@uns__nstudio?utm_source=eslfinder&utm_medium=referral',
    url: `https://images.unsplash.com/photo-1434030216411-0b793f4b4173?ixlib=rb-4.0.3${photoParams}`,
  };

  const specificPhoto = {
    author: material.photo?.user?.name,
    profile: `https://unsplash.com/@${material.photo?.user?.username}?utm_source=eslfinder&utm_medium=referral`,
    url: sanitizeUrl(`${material.photo?.urls?.raw}${photoParams}`),
  }

  return material.photo ? specificPhoto : defaultPhoto;
}

export function getPhotoPreview(material: Material) {
  const photoParams = '&auto=format&w=360&q=45';
  const defaultPhoto = 'https://images.unsplash.com/photo-1434030216411-0b793f4b4173?ixlib=rb-4.0.3';
  return (material.photo?.urls?.raw ? sanitizeUrl(material.photo?.urls?.raw) : defaultPhoto) + photoParams;
}


export function decodeMaterial(m: Material): Material {
  return {
    ...m,
    title: he.decode(m.title),
    description: m.description ? he.decode(m.description) : m.description,
    overview: m.overview ? he.decode(m.overview) : m.overview,
    theme: m.theme ? he.decode(m.theme) : m.theme,
    content: m.content ? he.decode(m.content) : m.content,
  }
}
