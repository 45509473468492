
export const SEARCH_LESSONS_PAGES = [
  'english-lessons',
  'free-english-lessons',
  'general-english-lessons',
  'grammar-english-lessons',
  'business-english-lessons',
  'beginner-english-lessons',
  'intermediate-english-lessons',
  'advanced-english-lessons',
  'english-lessons-for-kids',
  'english-lessons-for-tennagers',
  'english-lessons/esl-curriculum',
];


export function isMainLessonsPage() {
  return window.location.pathname === '/english-lessons';
}

export function isFreeLessonsPage() {
  return window.location.pathname === '/free-english-lessons';
}

export function isGeneralLessonsPage() {
  return window.location.pathname === '/general-english-lessons';
}

export function isGrammarLessonsPage() {
  return window.location.pathname === '/grammar-english-lessons';
}

export function isBusinessLessonsPage() {
  return window.location.pathname === '/business-english-lessons';
}

export function isBeginnerLessonsPage() {
  return window.location.pathname === '/beginner-english-lessons';
}

export function isIntermediateLessonsPage() {
  return window.location.pathname === '/intermediate-english-lessons';
}

export function isAdvancedLessonsPage() {
  return window.location.pathname === '/advanced-english-lessons';
}

export function isLessonsForKidsPage() {
  return window.location.pathname === '/english-lessons-for-kids';
}

export function isLessonsForTeenagersPage() {
  return window.location.pathname === '/english-lessons-for-teenagers';
}

export function shouldHideCategoryField() {
  return isGeneralLessonsPage() || isGrammarLessonsPage() || isBusinessLessonsPage();
}

export function shouldHideAgeField() {
  return isLessonsForKidsPage() || isLessonsForTeenagersPage();
}

export function shouldHideLevelField() {
  return isBeginnerLessonsPage() || isIntermediateLessonsPage() || isAdvancedLessonsPage();
}

export function isEslCurriculumPage() {
  return window.location.pathname === '/english-lessons/esl-curriculum';
}

export function buildSearchParamsFromPath(searchParams: URLSearchParams): URLSearchParams {

  if (isFreeLessonsPage()) {
    searchParams.append('plan', 'FREE');
  } else if (isGeneralLessonsPage()) {
    searchParams.append('category', 'GENERAL');
  } else if (isGrammarLessonsPage()) {
    searchParams.append('category', 'GRAMMAR');
  } else if (isBusinessLessonsPage()) {
    searchParams.append('category', 'BUSINESS');
  } else if (isBeginnerLessonsPage()) {
    searchParams.append('level', 'A1,A2');
  } else if (isIntermediateLessonsPage()) {
    searchParams.append('level', 'B1,B2');
  } else if (isAdvancedLessonsPage()) {
    searchParams.append('level', 'C1,C2');
  } else if (isLessonsForKidsPage()) {
    searchParams.append('age', 'KIDS');
  } else if (isLessonsForTeenagersPage()) {
    searchParams.append('age', 'TEENAGERS');
  } else if (isEslCurriculumPage()) {
    searchParams.append('source', 'esl-curriculum');
  }

  return searchParams;
}
