import { QueryClient } from '@tanstack/react-query';

const MINUTE = 1000 * 60;

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchIntervalInBackground: false,
      gcTime: 5 * MINUTE,
      staleTime: 5 * MINUTE,
      refetchOnWindowFocus: false,
      retry: 3,
      retryDelay: retryCount => Math.min(retryCount * 1000, 30000),
    },
  },
});

export default queryClient;
