import { FormControlLabel, FormGroup, Switch } from '@mui/material';
import { useAuth } from 'react-oidc-context';
import { useSearchParams } from 'react-router-dom';
import { hasSession } from '../../util/JwtUtils';
import { useLoginModal } from '../login/LoginProvider';


function FavoriteFilter() {
  const auth = useAuth();
  const { openLoginModal } = useLoginModal();
  const [searchParams, setSearchParams] = useSearchParams();

  const toggleFavorites = (_: React.ChangeEvent<HTMLInputElement>) => {
    const enabled = searchParams.get('favorites') === 'YES';

    if (!hasSession(auth)) {
      openLoginModal();
    } else {
      setSearchParams({ favorites: enabled ? 'NO' : 'YES' });
    }
  };

  return (
    <FormGroup>
      <FormControlLabel control={<Switch color='secondary' checked={searchParams.get('favorites') === 'YES'} onChange={toggleFavorites} />} label='Show only my bookmarked lessons' />
    </FormGroup>
  );
}

export default FavoriteFilter;
