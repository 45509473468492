import EditIcon from '@mui/icons-material/Edit';
import { Link } from '@mui/material';
import { useAuth } from 'react-oidc-context';
import { Material } from '../../../commons/openapi';
import { hasAdminPermission } from '../../../commons/util/JwtUtils';

interface Props {
  readonly material: Material;
}

function ManageIcon({ material }: Props) {
  const auth = useAuth();

  if (!hasAdminPermission(auth)) {
    return <></>;
  }

  return (
    <Link href={`/english-lesson/${material.id}/edit`}>
      <span style={{ display: 'flex', alignItems: 'center' }}>
        <EditIcon style={{ fill: '#2d877e', marginRight: '5px' }} />Edit
      </span>
    </Link>
  );
}

export default ManageIcon;
