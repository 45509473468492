import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { Button, Card, CardContent, CardMedia, Grid, Typography } from '@mui/material';
import ThemeFilter from '../../commons/components/filter/ThemeFilter';
import AccessPlanIcon from '../../commons/components/icon/AccessPlanIcon';
import AgeIcon from '../../commons/components/icon/AgeIcon';
import CategoryIcon from '../../commons/components/icon/CategoryIcon';
import FavoriteIcon from '../../commons/components/icon/FavoriteIcon';
import LevelIcon from '../../commons/components/icon/LevelIcon';
import ManageIcon from '../../commons/components/icon/ManageIcon';
import MediaTypeIcon from '../../commons/components/icon/MediaTypeIcon';
import TeacherVersionIcon from '../../commons/components/icon/TeacherVersionIcon';
import { Material } from '../../commons/openapi';
import { decodeMaterial, getPhotoPreview, isLesson } from '../../commons/util/MaterialUtils';


interface Props {
  readonly material: Material,
  readonly clickableTheme?: boolean
}

function MaterialCard({ material, clickableTheme }: Props) {
  const photoUrl = getPhotoPreview(material);
  const isAlesson = isLesson(material);
  const labelButton = isAlesson ? 'Show' : 'Read';
  const m = decodeMaterial(material);
  const url = isAlesson ? `../english-lesson/${m.id}` : `../blog/${m.id}`

  const overviewStile = {
    lineHeight: 1.4,
    margin: '10px 0 20px',
    height: '77px',
    overflow: 'hidden',
    display: '-webkit-box',
  };

  const cardStyle = {
    width: 360,
    height: isAlesson ? 500 : 440,
    border: '1px solid #2d877e',
    position: 'relative',
    boxShadow: 'none !important',
    '&:hover': {
      transition: 'all .3s ease',
      opacity: 0.9,
    }
  };

  const titleStyle = {
    fontWeight: 'bold',
    height: isAlesson ? '47px' : '65px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    marginBottom: isAlesson ? '5px' : '7px',
  };

  return (
    <Card sx={cardStyle}>
      {m.is_new && (<span className='new-icon' />)}
      <CardMedia component='img' width='360px' height='160px' sx={{ maxWidth: 360, objectFit: 'cover' }} image={photoUrl} alt={m.title} />

      <CardContent sx={{ textAlign: 'left' }}>
        {isAlesson && (
          <Grid container direction='row' alignItems='flex-start' gap={1} mb={2} fontSize={12} height={80}>
            <FavoriteIcon material={m} />
            <LevelIcon material={m} />
            <CategoryIcon material={m} />
            <AgeIcon material={m} />
            <MediaTypeIcon material={m} />
            <TeacherVersionIcon material={m} />
            <ManageIcon material={m} />
          </Grid>
        )}

        <Typography variant='h4' sx={titleStyle}>{m.title}</Typography>
        <ThemeFilter material={m} clickableTheme={clickableTheme} />
        <Typography variant='body2' color='text.secondary' sx={overviewStile}>{m.overview}</Typography>
        {isAlesson && (<AccessPlanIcon material={m} />)}
        <Button variant='contained' color='secondary' href={url} sx={{ float: 'right' }}>{labelButton} <ArrowForwardIosIcon style={{ fill: '#fff' }} /></Button>
      </CardContent>

    </Card>
  );
}

export default MaterialCard;
