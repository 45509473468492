import DateRangeIcon from '@mui/icons-material/DateRange';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { Box, Button, CircularProgress, Container, Grid, Link, Stack, Typography } from '@mui/material';
import { Helmet } from 'react-helmet-async';
import { Navigate, useParams } from 'react-router-dom';
import AdUnit from '../../commons/components/ads/AdUnit';
import BuyMeACoffe from '../../commons/components/BuyMeACoffe';
import Loading from '../../commons/components/Loading';
import env from '../../commons/configs/env';
import { useTextDebounce } from '../../commons/hooks/useDebounce';
import { toDDMMYYYY } from '../../commons/util/DateUtils';
import { decodeMaterial, getPhoto } from '../../commons/util/MaterialUtils';
import MaterialBookmark from '../../material/components/MaterialBookmark';
import MaterialCharacteristic from '../../material/components/MaterialCharacteristic';
import MaterialGrid from '../../material/components/MaterialGrid';
import { useFetchMaterial, useRedirect, } from '../../material/hooks/useMaterialAPI';

function LessonDetailPage() {
  const { id } = useParams();
  const { data, isFetched, isLoading } = useFetchMaterial(id!);
  const material = data ? decodeMaterial(data) : data;
  const debouncedUrl = useTextDebounce(data?.url, 3000);

  const { refetch: redirect } = useRedirect(id!);

  if (isFetched && !material) {
    return <Navigate to='/not-found' />
  }

  if (isLoading) {
    return <Loading />
  }

  if (!material) {
    return <></>
  }

  const title = `${material.title} | ESL Finder`;
  const url = `${env.canonical}/english-lesson/${material.id}`;
  const overview = material.overview ? material.overview : material.description!;
  const description = `${overview}`.slice(0, 100)
  const photo = getPhoto(material);

  return (
    <Container maxWidth='xl' sx={{ my: 4, maxWidth: '1264px !important' }}>

      <Helmet prioritizeSeoTags>
        <title>{title}</title>
        <link rel='canonical' href={url} />
        <meta name='description' content={description} />
        <meta property='og:site_name' content='ESL Finder' />
        <meta property='og:title' content={title} />
        <meta property='og:url' content={url} />
        <meta property='og:description' content={description} />
        <meta property='og:image' content={photo.url} />
        <meta property='og:type' content='website' />
      </Helmet>

      <Typography variant='h2' sx={{ my: 4, color: 'secondary.main', fontWeight: 'bold' }}>{material.title}</Typography>

      <Box pt={0} pb={4}>
        <AdUnit slot={env.ads.horizontal} align='left' />
      </Box>

      <Grid container spacing={4} maxWidth='xl'>

        <Grid item xs={12} sm={6}>
          <Box component='section'>
            <figure style={{ margin: 0, position: 'relative' }}>
              <img src={photo.url} loading='lazy' alt={material.title} style={{ maxWidth: '100%', objectFit: 'cover' }} width='600px' height='400px' />
              <figcaption style={{ textAlign: 'center' }}>
                <Typography variant='caption'>
                  Photo by <Link href={photo.profile}>{photo.author}</Link> on <Link href='https://unsplash.com/?utm_source=eslfinder&utm_medium=referral'>Unsplash</Link>
                </Typography>
              </figcaption>
            </figure>
          </Box>

          <Box component='section' sx={{ mt: 4 }}>
            <MaterialCharacteristic material={material} />
          </Box>
        </Grid>

        <Grid item xs={12} md={6}>
          <Box sx={{ textAlign: 'justify' }}>
            <Stack direction={{ xs: 'column', sm: 'row' }} sx={{ bgcolor: '#efefef', p: 1, my: 1, }} justifyContent='space-between' gap={1}>
              <MaterialBookmark material={material} />
              <Stack direction='row' gap={1}>
                <DateRangeIcon style={{ fill: '#2d877e', transform: 'scale(1.2)' }} />
                <Typography>{toDDMMYYYY(material.published_at)}</Typography>
              </Stack>
            </Stack>

            <Typography variant='body1'>{overview}</Typography>
          </Box>

          <Stack sx={{ alignItems: 'center', my: 6, maxWidth: '370px', mx: 'auto' }}>
            <Box sx={{ m: 1, position: 'relative' }}>
              <Button component={Link} variant='contained' disableElevation={true} sx={{ fontSize: '22px', fontWeight: 'bold', backgroundColor: 'secondary.main', borderRadius: '40px', px: '40px', py: '20px', width: '370px' }} disabled={!debouncedUrl} href={debouncedUrl} onClick={() => redirect()} rel='nofollow' target='_blank' referrerPolicy='no-referrer' endIcon={<OpenInNewIcon />}>Open Lesson</Button>
              {!debouncedUrl && (<CircularProgress size={24} sx={{ position: 'absolute', top: '50%', left: '50%', marginTop: '-12px', marginLeft: '-12px' }} />)}
            </Box>
            <Typography variant='caption' fontStyle={'italic'} textAlign={'center'}>
              The material will open on another page. The lessons are aggregated from various external sources. We do not control or own this content, and it may change without notice. If something goes wrong, please reach out to us <Link href='/contact' underline='hover'>here</Link>.
            </Typography>
          </Stack>
        </Grid>

        <Stack sx={{ alignItems: 'center', mx: 4, p: 4, bgcolor: '#efefef' }}>
          <Typography align='justify' sx={{ my: 2 }}>
            <em><b>Enjoying your visit?</b> We'd love your support to keep bringing valuable content and resources. Any contribution, big or small, makes a real difference. Thanks!</em>
          </Typography>
          <BuyMeACoffe />
        </Stack>
      </Grid>

      <Box component='section' sx={{ my: 4, textAlign: 'center' }}>
        <AdUnit slot={env.ads.horizontal} align='left' />
      </Box>

      <Box component='section' sx={{ my: 6 }}>
        <div id='disqus_thread'></div>
      </Box>

      <Box component='section' sx={{ my: 6 }}>
        <MaterialGrid />
      </Box>

    </Container>
  );
}

export default LessonDetailPage;
