import BookmarkIcon from '@mui/icons-material/Bookmark';
import BookmarkBorderOutlinedIcon from '@mui/icons-material/BookmarkBorderOutlined';
import { Box, styled, Tooltip } from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';
import { useAuth } from 'react-oidc-context';
import { useFindInteractionByUser } from '../../../interaction/hooks/useInteractionAPI';
import { useFavorite } from '../../../material/hooks/useMaterialAPI';
import { Material } from '../../openapi';
import { hasSession } from '../../util/JwtUtils';
import { useLoginModal } from '../login/LoginProvider';

const StyledBox = styled(Box)(({ theme }) => ({
  color: theme.palette.common.white,
  position: 'absolute',
  top: '8px',
  left: '8px',
  height: '30px',
  width: '30px',
  borderRadius: '50%',
  backgroundColor: '#2d877e',
  opacity: '80%',
  textAlign: 'center',
  padding: '10px 8px 5px',
  cursor: 'pointer'
}));


interface Props {
  readonly material: Material,
}

function FavoriteIcon({ material }: Props) {

  const queryClient = useQueryClient();
  const auth = useAuth();
  const { openLoginModal } = useLoginModal();
  const { data } = useFindInteractionByUser([]);

  const favorite = data?.favorites ? Array.from(data.favorites).includes(material.id) : false;

  const onSuccess = () => {
    queryClient.invalidateQueries({ queryKey: ['materials-summary'] });
    queryClient.invalidateQueries({ queryKey: ['interactions'] });
  }

  const onError = (error: any) => {
  }

  const favoriteMaterial = useFavorite(onSuccess, onError);

  const onClick = () => {
    if (hasSession(auth)) {
      favoriteMaterial.mutate(material.id);
    } else {
      openLoginModal();
    }
  };

  const iconStyle = { fill: '#fff', transform: 'scale(1.4)' };

  return <StyledBox onClick={onClick}>
    {favorite ?
      <Tooltip title='Remove from bookmarks'><BookmarkIcon style={iconStyle} /></Tooltip>
      :
      <Tooltip title='Bookmark this lesson'><BookmarkBorderOutlinedIcon style={iconStyle} /></Tooltip>
    }
  </StyledBox>;
}

export default FavoriteIcon;
