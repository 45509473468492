import { Container } from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { useSnackbar } from 'notistack';
import { Helmet } from 'react-helmet-async';
import { useNavigate, useParams } from 'react-router-dom';
import { Constraint, Material, MaterialNew } from '../../commons/openapi';
import { getPath } from '../../commons/util/MaterialUtils';
import MaterialForm from '../components/MaterialForm';
import { useFetchFullMaterial, useUpdateMaterial } from '../hooks/useMaterialAPI';


function MaterialEditPage() {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { id } = useParams();
  const { data } = useFetchFullMaterial(id!);
  const { enqueueSnackbar } = useSnackbar();

  const onError = (error: AxiosError) => {
    const constraint = error.response?.data as Constraint;
    const message = constraint.message ? constraint.message : 'Something went wrong.';

    enqueueSnackbar(message, { variant: 'error' });
  }

  const onSuccess = (material: Material) => {
    queryClient.invalidateQueries({ queryKey: ['material-full', id] });
    enqueueSnackbar('The material was updated successfully.', { variant: 'success' });
    console.log(material);
    navigate(`/${getPath(material)}/${id}`);
  }

  const updateLessonMutation = useUpdateMaterial(String(id), onSuccess, onError);

  const onSubmit = (materialNew: MaterialNew) => {
    updateLessonMutation.mutate(materialNew);
  };

  return (
    <Container>
      <Helmet>
        <title>Edit Material | ESL Finder</title>
        <meta name='robots' content='noindex, nofollow' />
      </Helmet>

      <MaterialForm material={data} onSubmit={onSubmit} updateMode />
    </Container>
  );
}

export default MaterialEditPage;
