import { createTheme } from '@mui/material';

// #2d877e verde #fbb03b amarelo #024f75 azul #6FA4E5 soft blue
export const theme = createTheme({
  palette: {
    mode: 'light',
    primary: {
      main: '#3f3f3f',
      contrastText: '#ffffff',
    },
    secondary: {
      main: '#2d877e',
    },
  },
  typography: {
    fontFamily: [
      'Montserrat',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    fontSize: 14,
    h1: {
      fontSize: '3rem',
    },
    h2: {
      fontSize: '2rem',
    },
    h3: {
      fontSize: '1.5rem',
    },
    h4: {
      fontSize: '1.15rem',
    },
    body1: {
      fontSize: '1.15rem',
      lineHeight: 1.5
    }
  }
});
