import axios, { AxiosInstance } from 'axios';
import { useAuth } from 'react-oidc-context';
import env from '../configs/env';

const applicationJson = 'application/json'

const useAxios = () => {
  const auth = useAuth();
  const user = auth.user;

  const axiosInstance: AxiosInstance = axios.create({
    baseURL: env.api.url,
    headers: {
      Accept: applicationJson,
      'Content-Type': applicationJson,
      'Access-Control-Allow-Origin': env.api.url
    }
  });

  axiosInstance.interceptors.request.use(async request => {
    request.headers.Authorization = auth.isAuthenticated && user && !user?.expired ? `Bearer ${user.access_token}` : null
    return request;
  });

  return axiosInstance;
}

export default useAxios;
