import DeleteIcon from '@mui/icons-material/Delete';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Stack, Typography } from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDeleteMaterial } from '../hooks/useMaterialAPI';


interface Props {
  readonly id: string,
  readonly open: boolean,
  readonly onClose: (open: boolean) => void
}

function MaterialDeleteDialog({ id, open, onClose }: Props) {
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const { enqueueSnackbar } = useSnackbar();

  const [visible, setVisible] = useState(false);


  const onSuccess = () => {
    queryClient.invalidateQueries({ queryKey: ['lesson-full', id] });
    enqueueSnackbar('The lesson was removed successfully.', { variant: 'success' });
    navigate(`/english-lessons`);
  }

  const onError = (error: any) => {
    enqueueSnackbar('Something went wrong.', { variant: 'error' });
  }

  const deleteLessonMutation = useDeleteMaterial(onSuccess, onError);

  const handleCloseDialog = () => {
    onClose(false);
    setVisible(false);
  };

  const handleDelete = () => {
    deleteLessonMutation.mutate(id);
    onClose(false);
    setVisible(false);
  };

  useEffect(() => {
    setVisible(open);
  }, [open]);

  return (
    <Dialog open={visible} onClose={handleCloseDialog}>
      <DialogTitle>
        <Stack alignItems='center' direction='row' gap={2}>
          <WarningAmberIcon />
          <Typography variant='h3' color='primary'>Are you sure?</Typography>
        </Stack>
      </DialogTitle>
      <DialogContent>
        <DialogContentText variant='body1' color='primary'>
          Do you really want to delete this record? This process cannot be undone.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button variant='contained' color='error' startIcon={<DeleteIcon />} onClick={handleDelete} autoFocus>Delete</Button>
        <Button variant='outlined' onClick={handleCloseDialog}>Cancel</Button>
      </DialogActions>
    </Dialog>
  );
}

export default MaterialDeleteDialog;
