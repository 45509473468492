/* tslint:disable */
/* eslint-disable */
/**
 * eslfinder-service API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0-SNAPSHOT
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @enum {string}
 */

export const AccessPlan = {
    Free: 'FREE',
    Premium: 'PREMIUM'
} as const;

export type AccessPlan = typeof AccessPlan[keyof typeof AccessPlan];



