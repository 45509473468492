import { Box, Container, Stack, Typography } from '@mui/material';
import { Helmet } from 'react-helmet-async';
import env from '../commons/configs/env';


function FaqPage() {

  const questions: { description: string, answer: string }[] = [
    {
      "description": "What is ESL Finder?",
      "answer": "ESL Finder is a website that brings together a collection of English Lessons from different websites so that you can find the lesson you want more quickly."
    },
    {
      "description": "Are lessons created by ESL Finder?",
      "answer": "No. There are several sites that make great lessons and we just help you find them."
    },
    {
      "description": "How do I access the lessons?",
      "answer": "On our search page, after clicking on the lesson's card the details page of that lesson will open. On this page, after a few seconds you can click on the 'Open' link to be redirected to the website that provides the lesson."
    },
    {
      "description": "Do I need credentials to access the lessons?",
      "answer": "To access the lessons, some sites require registration."
    },
    {
      "description": "Are the lessons free?",
      "answer": "There are free lessons and paid lessons in our collection. You can select them using the 'Plan' filter."
    },
  ];

  return (
    <Container component='article' maxWidth='xl'>

      <Helmet>
        <title>Frequently Asked Questions | ESL Finder</title>
        <link rel='canonical' href={`${env.canonical}/faq`} />
        <meta name='description' content='Frequently asked questions' />
      </Helmet>

      <Box width='100%' sx={{ textAlign: 'center', my: 8 }}>
        <Typography variant='h1'>Frequently Asked Questions</Typography>
      </Box>

      {questions?.map((question: { description: string, answer: string }, i: number) => (
        <Stack key={i} component='section' spacing={2} sx={{ textAlign: 'justify', my: 4 }}>
          <Typography variant='h2'>{question.description}</Typography>
          <Typography variant='body1'>{question.answer}</Typography>
        </Stack>
      ))}

    </Container>
  );
}

export default FaqPage;
