import { useQuery } from '@tanstack/react-query';
import { AxiosInstance } from 'axios';
import { useAuth } from 'react-oidc-context';
import env from '../../commons/configs/env';
import useAxios from '../../commons/hooks/useAxios';
import { InteractionResourceApi } from '../../commons/openapi/api/interaction-resource-api';
import { InteractionType } from '../../commons/openapi/model/interaction-type';
import { hasSession } from '../../commons/util/JwtUtils';


function interactionAPI(axios: AxiosInstance) {
  return new InteractionResourceApi(undefined, env.api.url, axios);
}

async function findInteractionByUser(axios: AxiosInstance, types: InteractionType[]) {
  const defaultTypes: InteractionType[] = [InteractionType.Favorite, InteractionType.Comment];
  const { data } = await interactionAPI(axios).findInteractionByUser(types.length > 0 ? types : defaultTypes);
  return data;
}

export const useFindInteractionByUser = (types: InteractionType[]) => {
  const auth = useAuth();
  const axios = useAxios();

  return useQuery({
    queryKey: ['interactions'],
    queryFn: () => findInteractionByUser(axios, types),
    enabled: hasSession(auth)
  });
}
