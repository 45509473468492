import { Box, Chip, Stack, Tooltip, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useDebounce } from '../../hooks/useDebounce';
import { AccessPlan, Age, Category, Level, MediaType, Status } from '../../openapi';
import { capitalize } from '../../util/String';


interface Props {
  readonly label: string,
  readonly description: string,
  readonly param: string,
}

function ChipFilter({ label, description, param }: Props) {
  const [searchParams, setSearchParams] = useSearchParams();
  const [searchParam, setSearchParam] = useState<string[]>(searchParams.get(param)?.split(',') || []);
  const debouncedParam: string[] = useDebounce(searchParam, 250);

  const onClick = (value: string) => {
    if (searchParam.includes(value)) {
      setSearchParam([...searchParam.filter(v => v !== value)])
    } else if (param === 'teacher_version' || param === 'plan') {
      setSearchParam([value]);
    } else {
      setSearchParam([...searchParam, value])
    }
  };

  useEffect(() => {
    setSearchParam(searchParams.get(param)?.split(',') || []);
  }, [searchParams]);

  useEffect(() => {
    if (debouncedParam.length > 0) {
      searchParams.set(param, debouncedParam.join(','));
    } else {
      searchParams.delete(param);
    }
    setSearchParams(searchParams);
  }, [debouncedParam]);


  return (
    <Box sx={{ p: 1 }}>
      <Stack direction='row' gap={0.5} >
        <Tooltip title={description} placement='right'>
          <Typography component='p' color='primary' sx={{ mb: 1, fontWeight: 600, }} >{label}</Typography>
        </Tooltip>
      </Stack>

      <Stack direction='row'>
        {getValues(param).map((value) => {
          return <Chip
            key={value}
            label={capitalize(value)}
            color='secondary'
            size='medium'
            variant={isSelected(value, searchParam) ? 'filled' : 'outlined'}
            sx={{ my: 0.5, mf: 0, mr: 0.5, fontWeight: 600, fontSize: 14, color: isSelected(value, searchParam) ? 'white' : '' }}
            onClick={() => onClick(value)} />
        })}
      </Stack>
    </Box>
  );
}

function isSelected(value: string, selectedValues: string[]) {
  return selectedValues && selectedValues.includes(value);
}

function getValues(type: string): string[] {
  switch (type) {
    case 'category': {
      return Array.from(Object.values(Category));
    }
    case 'level': {
      return Array.from(Object.values(Level));
    }
    case 'age': {
      return Array.from(Object.values(Age));
    }
    case 'media': {
      return Array.from(Object.values(MediaType));
    }
    case 'plan': {
      return Array.from(Object.values(AccessPlan));
    }
    case 'teacher_version': {
      return ['YES', 'NO'];
    }
    case 'status': {
      return Array.from(Object.values(Status));
    }
    default: {
      return []
    }
  }
}

export default ChipFilter;
