import { useInfiniteQuery, useMutation, useQuery } from '@tanstack/react-query';
import { AxiosInstance } from 'axios';
import { useSearchParams } from 'react-router-dom';
import env from '../../commons/configs/env';
import useAxios from '../../commons/hooks/useAxios';
import { MaterialFilter, buildMaterialFilter } from './MaterialFilter';
import { Material, MaterialNew, MaterialPage, MaterialResourceApi, Type } from '../../commons/openapi';


function materialAPI(axios: AxiosInstance) {
  return new MaterialResourceApi(undefined, env.api.url, axios);
}

async function createMaterial(axios: AxiosInstance, materialNew: MaterialNew) {
  const { data } = await materialAPI(axios).createMaterial(materialNew);
  return data;
}

async function updateMaterial(axios: AxiosInstance, id: string, materialNew: MaterialNew) {
  const { data } = await materialAPI(axios).updateMaterial(id, materialNew);
  return data;
}

async function deleteMaterial(axios: AxiosInstance, id: string) {
  const { data } = await materialAPI(axios).deleteMaterial(id);
  return data;
}

async function findMaterial(axios: AxiosInstance, id: string) {
  const { data } = await materialAPI(axios).findMaterial(id);
  return data;
}

async function findFullMaterial(axios: AxiosInstance, id: string) {
  const { data } = await materialAPI(axios).findFullMaterial(id);
  return data;
}

async function favoriteMaterial(axios: AxiosInstance, id: string) {
  const { data } = await materialAPI(axios).favoriteMaterial(id);
  return data;
}

async function findSummaryMaterials(axios: AxiosInstance, filter: MaterialFilter, page: number) {
  const teacherVersion = filter.teacherVersion && filter.teacherVersion?.includes('YES') ? true : filter.teacherVersion && filter.teacherVersion?.includes('NO') ? false : undefined;
  const favorites = filter.favorites && filter.favorites?.includes('YES') ? true : filter.favorites && filter.favorites?.includes('NO') ? false : undefined;

  const { data } = await materialAPI(axios).findSummaryMaterials(new Set(filter.plan), new Set(filter.age), new Set(filter.category), favorites,
    new Set(filter.level), new Set(filter.media), Number(page), filter.perPage, new Set(filter.sort), new Set(filter.source), filter.status, teacherVersion, filter.term, filter.type);
  return data;
}

async function redirect(axios: AxiosInstance, lessonId: string) {
  const { data } = await materialAPI(axios).redirectMaterial(lessonId);
  return data;
}


export const useCreateMaterial = (onSuccess: (data: Material) => void, onError: (data: any) => void) => {
  const axios = useAxios();

  return useMutation({
    mutationFn: (materialNew: MaterialNew) => createMaterial(axios, materialNew),
    onSuccess: onSuccess,
    onError: onError
  });
}

export const useUpdateMaterial = (id: string, onSuccess: (data: Material) => void, onError: (data: any) => void) => {
  const axios = useAxios();

  return useMutation({
    mutationFn: (materialNew: MaterialNew) => updateMaterial(axios, id, materialNew),
    onSuccess: onSuccess,
    onError: onError
  });
}

export const useDeleteMaterial = (onSuccess: () => void, onError: (data: any) => void) => {
  const axios = useAxios();

  return useMutation({
    mutationFn: (id: string) => deleteMaterial(axios, id),
    onSuccess: onSuccess,
    onError: onError
  });
}

export const useFetchMaterial = (id: string) => {
  const axios = useAxios();

  return useQuery({
    queryKey: ['material', id],
    queryFn: () => findMaterial(axios, id),
    enabled: Boolean(id)
  });
}

export const useFetchFullMaterial = (id: string) => {
  const axios = useAxios();

  return useQuery({
    queryKey: ['material-full', id],
    queryFn: () => findFullMaterial(axios, id),
    enabled: Boolean(id)
  });
}

export const useRedirect = (id: string) => {
  const axios = useAxios();

  return useQuery({
    queryKey: ['redirect', id],
    queryFn: () => redirect(axios, id),
    enabled: false
  });
}

export const useInfinityFetchSummaryMaterials = (type: Type) => {
  const axios = useAxios();
  const [searchParams] = useSearchParams();
  let materialFilter = buildMaterialFilter(searchParams);
  materialFilter = { ...materialFilter, type: [type] };

  return useInfiniteQuery({
    queryKey: ['materials-summary', materialFilter],
    queryFn: ({ pageParam }) => findSummaryMaterials(axios, materialFilter, pageParam),
    initialPageParam: 1,
    enabled: Boolean(materialFilter),
    getNextPageParam: (lastPage: MaterialPage, allPages: MaterialPage[]) => {
      const nextPage = allPages.length + 1;
      return lastPage.hasMore && nextPage <= 10 ? nextPage : undefined;
    }
  });
}

export const useFetchSummaryMaterials = (materialFilter: MaterialFilter) => {
  const axios = useAxios();

  return useQuery({
    queryKey: ['materials-summary-grid', materialFilter],
    queryFn: () => findSummaryMaterials(axios, materialFilter, 1),
    enabled: Boolean(materialFilter),
  });
}

export const useFavorite = (onSuccess: (data: void) => void, onError: (data: any) => void) => {
  const axios = useAxios();

  return useMutation({
    mutationFn: (id: string) => favoriteMaterial(axios, id),
    onSuccess: onSuccess,
    onError: onError
  });
}
