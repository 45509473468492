import { Box } from '@mui/material';
import MuiMarkdown from 'mui-markdown';
import { Material, MaterialNew } from '../../openapi';
import { isWorksheet } from '../../util/MaterialUtils';
import './index.css';

interface Props {
  readonly material: Material | MaterialNew
}

function MarkdownPreview({ material }: Props) {
  const classNames = isWorksheet(material) ? 'md md-worksheet' : 'md';

  return (
    <Box className={classNames}>
      <MuiMarkdown>{material.content}</MuiMarkdown>
    </Box>
  );
}

export default MarkdownPreview;
