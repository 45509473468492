import { User, WebStorageStateStore } from 'oidc-client-ts';
import { AuthProviderProps } from 'react-oidc-context';
import env from './env';

const OIDC_CONFIG: AuthProviderProps = {
  ...env.oidc,
  automaticSilentRenew: true,
  // login: process.env.REACT_APP_OIDC_URL + '/login',
  // loadUserInfo: true,
  silent_redirect_uri: process.env.REACT_APP_SILENT_REDIRECT_URL,
  // audience: 'https://example.com',
  redirect_uri: window.location.origin,
  // response_type: 'token',
  // grantType: 'password',
  // scope: 'openid',
  // webAuthResponseType: 'id_token token',
  userStore: new WebStorageStateStore({
    store: localStorage
  }),
  onSigninCallback: (_user: User | void): void => {
    const returnUrl = localStorage.getItem('returnUrl') || '/';
    window.history.replaceState({}, document.title, window.location.origin + window.location.pathname);
    window.location.href = returnUrl;
  },
  onSignoutCallback: (): void => {
    // didn't work because we are doing the signout manually
  }
};

export default OIDC_CONFIG;
