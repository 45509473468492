import ClearIcon from '@mui/icons-material/Clear';
import SearchIcon from '@mui/icons-material/Search';
import { IconButton } from '@mui/material';
import InputBase from '@mui/material/InputBase';
import { styled } from '@mui/material/styles';
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useDebounce } from '../../hooks/useDebounce';


const StyledInputBase = styled(InputBase)(({ theme }) => ({
  width: '99%',
  maxWidth: 1900,
  color: '#fff',
  fontSize: '1.1rem',
  padding: theme.spacing(1, 1, 0.8, 1.5),
  borderRadius: theme.spacing(1),
  backgroundColor: theme.palette.secondary.main,
  '& .MuiInputBase-input': {
    paddingLeft: theme.spacing(1.4),
  },
}));

function TermFilter() {
  const param = 'term';
  const [searchParams, setSearchParams] = useSearchParams();
  const [searchParam, setSearchParam] = useState<string>(searchParams.get(param) || '');
  const debouncedParam: string = useDebounce(searchParam, 750);


  useEffect(() => {
    if (debouncedParam && debouncedParam.length > 0) {
      searchParams.set(param, debouncedParam || '');
    } else {
      searchParams.delete(param);
    }

    setSearchParams(searchParams);
  }, [debouncedParam]);

  useEffect(() => {
    setSearchParam(searchParams.get(param) || '');
  }, [searchParams]);

  return (
    <StyledInputBase
      placeholder='Find me English lessons about...'
      onChange={(e) => setSearchParam(e.target.value)}
      sx={{
        margin: { xs: '0' }
      }}
      value={searchParam}
      startAdornment={<SearchIcon sx={{ color: 'white' }} />}
      endAdornment={
        <IconButton onClick={() => setSearchParam('')} sx={{ visibility: searchParam ? 'visible' : 'hidden' }}>
          <ClearIcon sx={{ color: 'white' }} />
        </IconButton>
      } />
  );
}

export default TermFilter;
