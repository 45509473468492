import { useEffect } from 'react';
import { useAuth } from 'react-oidc-context';
import { Navigate, Route, Routes, useNavigate, useParams } from 'react-router-dom';
import BlogSearchPage from '../../../blog/BlogSearchPage';
import PostPage from '../../../blog/PostPage';
import LessonDetailPage from '../../../lesson/pages/LessonDetailPage';
import LessonSearchPage from '../../../lesson/pages/LessonSearchPage';
import MaterialEditPage from '../../../material/pages/MaterialEditPage';
import MaterialNewPage from '../../../material/pages/MaterialNewPage';
import AboutPage from '../../../pages/AboutPage';
import ContactPage from '../../../pages/ContactPage';
import CookiePolicyPage from '../../../pages/CookiePolicyPage';
import FaqPage from '../../../pages/FaqPage';
import HomePage from '../../../pages/HomePage';
import NotFoundPage from '../../../pages/NotFoundPage';
import PermissionDeniedPage from '../../../pages/PermissionDeniedPage';
import PrivacyPolicyPage from '../../../pages/PrivacyPolicyPage';
import { hasAdminPermission } from '../../util/JwtUtils';
import { SEARCH_LESSONS_PAGES } from '../../util/PageUtils';
import { Box } from '@mui/material';


export default function AppRoutes() {

  return (
    <Box minHeight={4000} m={0} p={0}>
      <Routes>
        <Route path='/' element={<HomePage />} />
        <Route path='about' element={<AboutPage />} />
        <Route path='privacy-policy' element={<PrivacyPolicyPage />} />
        <Route path='cookie-policy' element={<CookiePolicyPage />} />
        <Route path='contact' element={<ContactPage />} />
        <Route path='faq' element={<FaqPage />} />

        <Route path='create' element={<Protected><MaterialNewPage /></Protected>} />

        <Route path='blog' element={<BlogSearchPage />} />
        <Route path='blog/:id' element={<PostPage />} />
        <Route path='blog/:id/edit' element={<Protected><MaterialEditPage /></Protected>} />

        <Route path='search' element={<Navigate to='/english-lessons' replace />} />
        <Route path='lesson' element={<Navigate to='/english-lessons' replace />} />

        {SEARCH_LESSONS_PAGES.map((page: string) => <Route key={page} path={page} element={<LessonSearchPage />} />)}

        <Route path='lesson/:id' element={<LessonDetailRedirect />} />
        <Route path='english-lesson/:id' element={<LessonDetailPage />} />
        <Route path='english-lesson/:id/edit' element={<Protected><MaterialEditPage /></Protected>} />

        <Route path='/permission-denied' element={<PermissionDeniedPage />} />
        <Route path='/not-found' element={<NotFoundPage />} />
        <Route path='*' element={<Navigate to='/not-found' replace />} />
      </Routes>
    </Box>
  );
}

function Protected({ children }: { children: JSX.Element }) {
  const auth = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (!hasAdminPermission(auth)) {
      navigate('/permission-denied');
    }
  }, [auth, navigate]);

  return children;
}

function LessonDetailRedirect() {
  const { id } = useParams();
  return <Navigate to={`/english-lesson/${id}`} replace />;
};
