import { Box, Link } from '@mui/material';

function BuyMeACoffe() {

  return (
    <Box sx={{ mt: 2, ml: 1 }}>
      <Link href='https://www.buymeacoffee.com/eslfinder' target='_blank'>
        <img src='https://cdn.buymeacoffee.com/buttons/v2/default-yellow.png' loading='lazy' alt='Buy Me A Coffee' style={{ height: 60, width: 217 }} />
      </Link>
    </Box>
  );
}

export default BuyMeACoffe;
