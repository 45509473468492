import HeadphonesIcon from '@mui/icons-material/Headphones';
import OndemandVideoIcon from '@mui/icons-material/OndemandVideo';
import SlideshowIcon from '@mui/icons-material/Slideshow';
import { Material } from '../../../commons/openapi';
import { capitalize } from '../../../commons/util/String';
import { hasAudio, hasMediaType, hasSlide, hasVideo } from '../../util/MaterialUtils';

interface Props {
  readonly material: Material,
}

function MediaTypeIcon({ material }: Props) {
  const medias = material.media_type ? Array.from(material.media_type) : [];
  const title = medias.length > 0 ? capitalize(medias.join(', ')) : '';

  const iconStyle = { fill: '#2d877e', marginRight: '5px' };

  if (!hasMediaType(material)) {
    return <></>
  }

  return <span style={{ display: 'flex', alignItems: 'center' }}>
    {hasSlide(material) ? <SlideshowIcon style={iconStyle} /> : hasVideo(material) ? <OndemandVideoIcon style={iconStyle} /> : hasAudio(material) ? <HeadphonesIcon style={iconStyle} /> : <></>}
    {title}
  </span>;
}

export default MediaTypeIcon;
