import { Box, Typography } from '@mui/material';
import { Material } from '../../../commons/openapi';
import { isFree } from '../../util/MaterialUtils';

interface Props {
  readonly material: Material,
}

function AccessPlanIcon({ material }: Props) {

  return (
    <Box sx={{ display: 'inline-block', mt: '10px' }}>
      <Typography variant='inherit' fontWeight='bold' color='common.black'>{isFree(material) ? 'Free' : 'Premium'}</Typography>
    </Box>
  );
}

export default AccessPlanIcon;
