import ArticleIcon from '@mui/icons-material/Article';
import { Material } from '../../openapi';
import { hasTeacherVersion } from '../../util/MaterialUtils';

interface Props {
  readonly material: Material,
}

function TeacherVersionIcon({ material }: Props) {
  if (!hasTeacherVersion(material)) {
    return <></>
  }

  return <span style={{ display: 'flex', alignItems: 'center' }}>
    <ArticleIcon style={{ fill: '#2d877e', marginRight: '5px' }} />Includes Teacher Version
  </span>;
}

export default TeacherVersionIcon;
