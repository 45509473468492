import { Box, Button, Container, Typography } from '@mui/material';
import { Helmet } from 'react-helmet-async';

function NotFoundPage() {

  const handleBackToHome = () => {
    window.location.href = '/';
  };

  return (
    <Container sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh', textAlign: 'center', }}>
      <Helmet>
        <title>Page Not Found | ESL Finder</title>
        <meta name='robots' content='noindex, nofollow' />
      </Helmet>

      <Box sx={{ backgroundColor: '#f5f5f5', padding: '2rem', borderRadius: '8px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' }}>
        <Typography variant='h3' sx={{ fontWeight: 'bold', marginBottom: '1rem' }}>
          Page Not Found!
        </Typography>
        <Typography variant='body1' sx={{ marginBottom: '2rem' }}>
          The page you are looking for does not exist.
        </Typography>
        <Button variant='contained' color='primary' sx={{ marginTop: '1rem' }} onClick={handleBackToHome} >
          Go to the home page
        </Button>
      </Box>
    </Container>
  );
};

export default NotFoundPage;
