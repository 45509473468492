import CloseIcon from '@mui/icons-material/Close';
import GoogleIcon from '@mui/icons-material/Google';
import { Box, Button, Grid, IconButton, Modal, Typography } from '@mui/material';
import { SyntheticEvent } from 'react';
import { useAuth } from 'react-oidc-context';
import { useLoginModal } from './LoginProvider';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  maxWidth: '840px',
  width: '90%',
  maxHeight: 524,
  bgcolor: 'background.paper',
  Border: 'none',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
};


function LoginModal() {
  const { isOpen, closeLoginModal } = useLoginModal();
  const auth = useAuth();

  const saveCurrentUrl = (): void => {
    localStorage.setItem('returnUrl', window.location.href);
  }

  const onClickGoogle = (event: SyntheticEvent): void => {
    saveCurrentUrl();
    auth.signinRedirect({ extraQueryParams: { identity_provider: 'Google' } });
  }

  return (
    <Modal open={isOpen} onClose={closeLoginModal}>
      <Box sx={style}>

        <Grid container>
          <Grid item xs={12} md={7} sx={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', textAlign: 'center', px: 5, py: '20px' }}>
            <Typography id='modal-title' variant='h2' fontWeight={'bold'}>Sign in to your account</Typography>
            <Typography component='p' mt={1} mb={4} color='GrayText'>Access your resources • Bookmark lessons • Filter content to your preferences</Typography>
            <Button id='bt-google-login' variant='contained' startIcon={<GoogleIcon />} sx={{ mt: 2, mb: 1, height: '60px', width: '100%', bgcolor: '#2d877e', fontSize: 16 }} onClick={onClickGoogle}>Login with Google</Button>
          </Grid>
          <Grid item md={5} position={'relative'} display={{xs: 'none', md: 'block'}}>
            <IconButton onClick={closeLoginModal} sx={{ color: '#fff', position: 'absolute', top: 10, right: 10, scale: '1.6' }}>
              <CloseIcon />
            </IconButton>
            <Box component='img' alt='Sign-in' src='/login.jpg'
              sx={{
                height: 524,
                width: 350,
              }}
            />
          </Grid>
        </Grid>

      </Box>

    </Modal>
  );
};

export default LoginModal;
