import ChildCareIcon from '@mui/icons-material/ChildCare';
import PeopleIcon from '@mui/icons-material/People';
import { Box } from '@mui/material';
import { Age, Material } from '../../../commons/openapi';
import { capitalize } from '../../../commons/util/String';

interface Props {
  readonly material: Material,
}

function AgeIcon({ material }: Props) {
  const age = material.age ? Array.from(material.age) : [];
  const label = age.length > 0 ? capitalize(age.join(', ')) : '';

  const isKids = age.includes(Age.Kids) && age.length === 1;
  const hasAge = age.length > 0;

  const iconStyle = { fill: '#2d877e', marginRight: '5px' };

  if (!hasAge) {
    return <></>
  }

  return (
    <Box>
      <span style={{ display: 'flex', alignItems: 'center' }}>
        {isKids ? <ChildCareIcon style={iconStyle} /> : <PeopleIcon style={iconStyle} />}
        {label}
      </span>
    </Box>
  );
}

export default AgeIcon;
