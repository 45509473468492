import { ReactNode, createContext, useCallback, useContext, useState } from 'react';

interface LoginContextProps {
  isOpen: boolean;
  openLoginModal: () => void;
  closeLoginModal: () => void;
}

interface Props {
  children: ReactNode
}

const LoginContext = createContext<LoginContextProps | undefined>(undefined);

export function LoginProvider({ children }: Props) {
  const [isOpen, setIsOpen] = useState(false);

  const openLoginModal = useCallback(() => {
    setIsOpen(true);
  }, []);

  const closeLoginModal = useCallback(() => {
    setIsOpen(false);
  }, []);

  return (
    <LoginContext.Provider value={{ isOpen, openLoginModal, closeLoginModal }}>
      {children}
    </LoginContext.Provider>
  );
};

export const useLoginModal = (): LoginContextProps => {
  const context = useContext(LoginContext);
  if (!context) {
    throw new Error('useLoginModal must be used within a LoginProvider');
  }
  return context;
};
